import React from 'react';

import ReactDOM from 'react-dom';

import App from './App';
import AppError from './components/Error';
import reportWebVitals from './reportWebVitals';
import { authStore } from './stores/auth';
import { initAppInsights } from './utils/app-insights';

initAppInsights();

authStore
  .onAppStart()
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root'),
    );
  })
  .catch(() => {
    ReactDOM.render(
      <React.StrictMode>
        <AppError />
      </React.StrictMode>,
      document.getElementById('root'),
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
