import { action, computed, makeObservable, observable } from 'mobx';

import { ArtifactsApi } from '../api/artifacts';
import { HomeArtifacts } from '../api/api-types/artifacts';
import { storeFactory } from '../utils/store';
import { ApiReq, emptyValue } from '../api';

class ArtifactsStore {
  api = new ArtifactsApi();
  @observable.ref homeCountsReq: ApiReq<HomeArtifacts> = emptyValue;
  constructor() {
    makeObservable(this);
  }

  @computed get counts() {
    if (this.homeCountsReq.state !== 'fulfilled') {
      return null;
    }

    return this.homeCountsReq.value.data?.artifactsCounts;
  }

  @action getHomeCounts = () => (this.homeCountsReq = this.api.getHome());
}

export const {
  store: artifactsStore,
  storeCtx: artifactsStoreCtx,
} = storeFactory(ArtifactsStore, 'artifacts');
