import { initRouter, IRoute, RouterStore } from 'mobx-router6';

import { routes } from '../routes';
import { routesUnauth } from '../routes.unauth';

type RouteModType = 'auth' | 'unauth';

export let routerStore: RouterStore<IRoute> | null = null;
let currentRouteMode: RouteModType = 'unauth';

export const createRouter = ({ authenticated = false }) => {
  if (routerStore) {
    const isAuthStoreReady = currentRouteMode === 'auth' && authenticated;
    const isUnAuthStoreReady = currentRouteMode === 'unauth' && !authenticated;

    if (isAuthStoreReady || isUnAuthStoreReady) {
      return routerStore;
    }

    routerStore.router.stop();
  }

  if (authenticated) {
    routerStore = initRouter<any>(routes);
    routerStore.router.start();
    currentRouteMode = 'auth';

    return routerStore;
  }

  routerStore = initRouter<any>(routesUnauth);
  routerStore.router.start();
  currentRouteMode = 'unauth';

  return routerStore;
};
