import { SvgIconProps } from '@material-ui/core/SvgIcon';
import LayoutDay from 'src/assets/icons/LayoutDayIcon';
import LayoutMonth from 'src/assets/icons/LayoutMonthIcon';
import LayoutWeek from 'src/assets/icons/LayoutWeekIcon';
import { AgendaIntervalType } from 'src/types';
import { WithMonteraColors } from 'src/utils/theme/montera-main';

export const iconsMap: Record<
  AgendaIntervalType,
  (props: WithMonteraColors<SvgIconProps>) => JSX.Element
> = {
  day: LayoutDay,
  week: LayoutWeek,
  month: LayoutMonth,
};
