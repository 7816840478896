import React, { useContext } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { ThemeProvider } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { TypedRoute, useRoute } from 'mobx-router6';
import { I18nextProvider } from 'react-i18next';

import HandleSystemError from './components/HandleSystemError';
import Layout from './components/Layout';
import { authStoreCtx } from './stores/auth';
import { createRouter } from './stores/router';
import i18n from './utils/i18n';
import { WithLayoutInnerBar } from './utils/router';
import { theme } from './utils/theme/montera-main';

function App() {
  const { isLoggedIn, currentAccount } = useContext(authStoreCtx);
  const routerStore = createRouter({ authenticated: isLoggedIn });
  const { WrapperComponent, RouteComponent } = useRoute({
    defaultWrapper: isLoggedIn ? Layout : null,
  });
  if (!routerStore.routes.hasOwnProperty(routerStore.route.name)) {
    routerStore.goTo({ name: '404' });
  }
  const currentRoute = routerStore.routes[routerStore.route.name];
  const LayoutInnerBar =
    (currentRoute as WithLayoutInnerBar<TypedRoute<string>>).layoutInnerBar ||
    null;
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <HandleSystemError>
          <WrapperComponent
            currentAccount={currentAccount}
            currentRoute={routerStore.currentRouteName}
            LayoutInnerBar={LayoutInnerBar}>
            <React.Suspense fallback={<CircularProgress />}>
              {RouteComponent && <RouteComponent />}
            </React.Suspense>
          </WrapperComponent>
        </HandleSystemError>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default observer(App);
