import { useContext } from 'react';

import { userStoreCtx } from 'src/stores/user';

import AuthError from './AuthError';
import SystemError from './SystemError';

const AppError = () => {
  const { userDataReq } = useContext(userStoreCtx);

  if (userDataReq.state === 'rejected') {
    return <AuthError />;
  }

  return <SystemError />;
};

export default AppError;
