import { PropsWithChildren, useContext } from 'react';

import { AccountInfo } from '@azure/msal-browser';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { routerStore, RouterStoreCtx } from 'mobx-router6';
import { useTranslation } from 'react-i18next';
import ShoppingBagIcon from 'src/assets/icons/ShoppingBagIcon';
import { useStyles } from 'src/components/Layout/mobile/styles';
import { RouteName } from 'src/routes';
import { ViewLineItemType } from 'src/types';

import { TaskDetailsTabs } from '../../types';

interface Props {
  currentAccount: AccountInfo | null;
  currentRoute: RouteName;
}

export default ({ children }: PropsWithChildren<Props>) => {
  const { wrapper, colorPrimary } = useStyles();
  const { t } = useTranslation('lineItems');
  const { route } = useContext(RouterStoreCtx);
  //todo https://dev.azure.com/Svalbard/MonteraHub/_workitems/edit/8935
  const isEnabled = false;

  const goBack = () =>
    routerStore?.goTo({
      name: 'task',
      params: { id: route.params?.id, tab: TaskDetailsTabs.LineItems },
    });
  const linkToCurrentInventory = () => {
    routerStore?.goTo({
      name: 'add-line-items',
      params: { id: route.params?.id, view: ViewLineItemType.Current },
    });
  };

  return (
    <div className={wrapper}>
      <AppBar position="static" classes={{ colorPrimary }}>
        <Toolbar
          style={{ display: 'grid', gridTemplateColumns: '36px 0.9fr 30px' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={goBack}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">{t('add_items')}</Typography>
          {isEnabled && (
            <IconButton onClick={linkToCurrentInventory}>
              <Typography
                color="primary"
                style={{
                  textTransform: 'uppercase',
                  fontSize: 14,
                  paddingRight: 8,
                }}>
                {t('inventory')}
              </Typography>
              <ShoppingBagIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      {children}
    </div>
  );
};
