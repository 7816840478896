import { TimesheetApi } from './api';
import { ApiReq, emptyValue } from 'src/api';
import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { storeFactory } from 'src/utils/store';
import {
  GetTimesheetQueryParams,
  TimesheetResponse,
} from './api-types';
import { Timesheet } from './models/timesheet';
import isToday from 'date-fns/isToday';
import { TimesheetRangeType } from 'src/types';
import sub from 'date-fns/sub';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import {
  formatMinutesDuration,
  getWeekRangeByStartDate,
  removeTimezoneEndOfDay,
  removeTimezoneStartOfDay,
  toBrowserDateFormat,
} from 'src/utils/date';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import isYesterday from 'date-fns/isYesterday';
import head from 'lodash/head';
import last from 'lodash/last';
import eachWeekOfInterval from 'date-fns/eachWeekOfInterval';
import isWithinInterval from 'date-fns/isWithinInterval';
import add from 'date-fns/add';
import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import addSeconds from 'date-fns/addSeconds';
import isSameDay from 'date-fns/isSameDay';
import { routerStore } from 'src/stores/router';
import { lookupsStore } from 'src/stores/lookups';
import { downloadBlob } from 'src/utils/api';
import { startOfDay } from 'date-fns';

const getRangeParams = (range: TimesheetRangeType) => {
  switch (range) {
    case TimesheetRangeType.today:
      return {
        startDate: new Date(),
        endDate: new Date(),
      };
    case TimesheetRangeType.yesterday:
      return {
        startDate: sub(new Date(), { days: 1 }),
        endDate: sub(new Date(), { days: 1 }),
      };
    case TimesheetRangeType.this_week:
      return {
        startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
        endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
      };
    case TimesheetRangeType.last_week:
      const somewhereLastWeek = sub(new Date(), { weeks: 1 });

      return {
        startDate: startOfWeek(somewhereLastWeek, { weekStartsOn: 1 }),
        endDate: endOfWeek(somewhereLastWeek, { weekStartsOn: 1 }),
      };
    case TimesheetRangeType.this_month:
      return {
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
      };
    case TimesheetRangeType.last_month:
      const somewhereLastMonth = sub(new Date(), { months: 1 });

      return {
        startDate: startOfMonth(somewhereLastMonth),
        endDate: endOfMonth(somewhereLastMonth),
      };
    default:
      return {
        startDate: new Date(),
        endDate: new Date(),
      };
  }
};

export class TimesheetStore {
  readonly api = new TimesheetApi();
  @observable.ref timesheetReq: ApiReq<TimesheetResponse> = emptyValue;
  @observable.ref timesheets: IObservableArray<Timesheet> = observable([]);
  @observable startDate: Date = new Date();
  @observable endDate: Date = new Date();
  @observable pageSize: number = 999;

  constructor({ isExtended = false }: { isExtended?: boolean } = {}) {
    makeObservable(this);

    if (!isExtended) {
      reaction(
        () => [this.startDate, this.endDate, this.pageSize],
        this.fetch,
        {
          name: 'TimesheetStore.ChangedParams',
        },
      );
    }
  }

  @computed get isLastWeekRange() {
    const { startDate, endDate } = getRangeParams(TimesheetRangeType.last_week);

    return (
      isSameDay(startDate, this.startDate) && isSameDay(endDate, this.endDate)
    );
  }

  @computed get isThisWeekRange() {
    const { startDate, endDate } = getRangeParams(TimesheetRangeType.this_week);

    return (
      isSameDay(startDate, this.startDate) && isSameDay(endDate, this.endDate)
    );
  }

  @computed get isThisMonthRange() {
    const { startDate, endDate } = getRangeParams(
      TimesheetRangeType.this_month,
    );

    return (
      isSameDay(startDate, this.startDate) && isSameDay(endDate, this.endDate)
    );
  }

  @computed get isLastMonthRange() {
    const { startDate, endDate } = getRangeParams(
      TimesheetRangeType.last_month,
    );

    return (
      isSameDay(startDate, this.startDate) && isSameDay(endDate, this.endDate)
    );
  }

  @computed get currentTimesheetRange() {
    if (isToday(this.startDate) && isToday(this.endDate)) {
      return TimesheetRangeType.today;
    }

    if (isYesterday(this.startDate) && isYesterday(this.endDate)) {
      return TimesheetRangeType.yesterday;
    }

    if (this.isThisWeekRange) {
      return TimesheetRangeType.this_week;
    }

    if (this.isLastWeekRange) {
      return TimesheetRangeType.last_week;
    }

    if (this.isThisMonthRange) {
      return TimesheetRangeType.this_month;
    }

    if (this.isLastMonthRange) {
      return TimesheetRangeType.last_month;
    }

    return TimesheetRangeType.custom;
  }

  @computed get timesheetsMapByDay() {
    return this.timesheets.reduce<Record<string, Timesheet[]>>(
      (acc, timesheet) => {
        const date = timesheet.job.dateDay;

        return {
          ...acc,
          [date]: [...(acc[date] || []), timesheet],
        };
      },
      {},
    );
  }

  @computed get timesheetsByDay() {
    return Object.values(this.timesheetsMapByDay);
  }

  @computed get timesheetsMapByWeek() {
    const start = head(this.timesheets)?.job.dateDay;
    const end = last(this.timesheets)?.job.dateDay;

    const weeks = eachWeekOfInterval(
      {
        start: start ? new Date(start) : new Date(),
        end: end ? new Date(end) : new Date(),
      },
      { weekStartsOn: 1 },
    );

    return weeks.reduce((acc, weekStart) => {
      const days = Object.keys(this.timesheetsMapByDay).filter(day => {
        return isWithinInterval(new Date(day), {
          start: weekStart,
          end: add(weekStart, { weeks: 1 }),
        });
      });

      if (days.length === 0) return acc;

      const daysMap = days.reduce(
        (acc, day) => ({
          ...acc,
          [day]: this.timesheetsMapByDay[day],
        }),
        {} as Record<string, Timesheet[]>,
      );

      return {
        ...acc,
        [weekStart.toISOString()]: daysMap,
      };
    }, {} as Record<string, Record<string, Timesheet[]>>);
  }

  @computed get timesheetsWeeks() {
    return Object.keys(this.timesheetsMapByWeek).map(weekStart => {
      const totalWeekMinutes = Object.values(
        this.timesheetsMapByWeek[weekStart],
      )
        .reduce((acc, item) => [...acc, ...item], [] as Timesheet[])
        .reduce((acc, item) => {
          return acc + item.totalDurationMinutes;
        }, 0);

      return {
        range: getWeekRangeByStartDate(new Date(weekStart)),
        timesheets: Object.values(this.timesheetsMapByWeek[weekStart]),
        total: formatMinutesDuration(totalWeekMinutes),
      };
    });
  }

  @computed get containsWeek() {
    return (
      differenceInCalendarWeeks(addSeconds(this.endDate, 1), this.startDate) >=
      1
    );
  }

  @computed get containsMonth() {
    return (
      differenceInCalendarMonths(addSeconds(this.endDate, 1), this.startDate) >=
      1
    );
  }

  @computed get totalDuration() {
    const totalMinutes = Object.values(this.timesheetsMapByDay)
      .reduce((acc, item) => [...acc, ...item], [] as Timesheet[])
      .reduce((acc, item) => {
        return acc + item.totalDurationMinutes;
      }, 0);

    return formatMinutesDuration(totalMinutes);
  }

  @action fetch = async () => {
    this.timesheetReq = this.api.getTimesheet({
      startDate: removeTimezoneStartOfDay(this.startDate.toISOString()),
      endDate: removeTimezoneEndOfDay(this.endDate.toISOString()),
      pageSize: this.pageSize,
    });

    await lookupsStore.ensureJobTypes();
    await this.timesheetReq;

    runInAction(() => {
      if (
        this.timesheetReq.state !== 'fulfilled' ||
        !this.timesheetReq.value.data
      ) {
        return;
      }

      this.timesheets.replace(
        this.timesheetReq.value.data.timesheet.map(
          ({ timePeriods, job }) =>
            new Timesheet({
              timePeriods,
              job,
              jobTypes: lookupsStore.jobTypes,
            }),
        ),
      );
    });
  };

  @action setTimesheetParams = ({
    startDate,
    endDate,
    pageSize,
  }: Partial<GetTimesheetQueryParams>) => {
    if (startDate) {
      this.startDate = new Date(startDate);
    }

    if (endDate) {
      this.endDate = new Date(endDate);
    }

    if (pageSize) {
      this.pageSize = pageSize;
    }
  };

  @action setTimesheetRange = (range: TimesheetRangeType) => {
    if (range !== TimesheetRangeType.custom) {
      const { startDate, endDate } = getRangeParams(range);

      this.startDate = startDate;
      this.endDate = endDate;
    }

    routerStore?.setRouteParams({
      params: {
        ...routerStore?.route.params,
        startDate: toBrowserDateFormat(this.startDate),
        endDate: toBrowserDateFormat(this.endDate),
      },
    });
  };

  @action download = async (taskId: string) => {
    const { data } = await this.api.downloadTimesheet({
      startDate: startOfDay(this.startDate).toISOString(),
      endDate: removeTimezoneEndOfDay(this.endDate.toISOString()),
      taskId: taskId,
      filter: '',
    });

    if (!data) return;

    downloadBlob(data, 'timesheet.xlsx');
  };
}

export const {
  store: timesheetStore,
  storeCtx: timesheetStoreCtx,
} = storeFactory(TimesheetStore, 'timesheet');

