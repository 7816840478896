import { useContext } from 'react';

import { AccountInfo } from '@azure/msal-browser';
import { observer } from 'mobx-react-lite';
import { RouterStoreCtx } from 'mobx-router6';
import AvailableItems from 'src/pages/AddLineItems/HeaderWrapper/AvailableItems';
import OtherViewHeader from 'src/pages/AddLineItems/HeaderWrapper/OtherViewHeader';
import { RouteName } from 'src/routes';
import { ViewLineItemType } from 'src/types';

interface Props {
  currentAccount: AccountInfo | null;
  currentRoute: RouteName;
}

export default observer((props: Props) => {
  const { route } = useContext(RouterStoreCtx);

  switch (route.params.view) {
    case ViewLineItemType.Available:
      return <AvailableItems {...props} />;
    default:
      return <OtherViewHeader {...props} />;
  }
});
