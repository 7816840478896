import { lazy } from 'react';

import { artifactsStore } from 'src/stores/artifacts';
import { MonteraRoute } from 'src/utils/router';

export const HomepageRoute: MonteraRoute<'index'> = {
  name: 'index',
  path: '/',
  comp: () => lazy(() => import('./index')),

  activate: artifactsStore.getHomeCounts,
};
