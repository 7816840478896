import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const AgendaNavigationIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -1 18 18"
    {...props}>
    <rect y="1" width="16" height="3" rx="1" fill="#fff" />
    <rect y="6" width="16" height="3" rx="1" fill="#fff" fillOpacity=".5" />
    <rect y="11" width="16" height="3" rx="1" fill="#fff" fillOpacity=".5" />
  </SvgIcon>
);

export default AgendaNavigationIcon;
