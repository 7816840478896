import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const DownloadIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 18"
    {...props}>
    <path
      d="M1 13v2.4A1.6 1.6 0 002.6 17h11.2a1.6 1.6 0 001.6-1.6V13M5 9l3.2 3.2L11.4 9M8.2 1v11.2"
      stroke="#2B59C3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default DownloadIcon;
