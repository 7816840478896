import { makeStyles } from '@material-ui/core/styles';

export const styles = {
  root: {
    padding: 30,

    '& > h3': {
      fontSize: '1.5rem',
      padding: '30px 0',
    },

    '& > h4': {
      fontSize: '1rem',
    },

    '& > button': {
      marginTop: 30,
    },
  },
  logo: {
    width: 167,
    height: 57,
  },
};

export const useStyles = makeStyles(styles);
