import { lazy } from 'react';

import { fromBrowserDateFormat } from 'src/utils/date';
import { MonteraRoute } from 'src/utils/router';

import AgendaLayout from './AgendaLayout';
import { agendaStore } from './store';

interface Params {
  startDate?: string;
  endDate?: string;
}

export const AgendaRoute: MonteraRoute<'agenda', Params> = {
  name: 'agenda',
  path: '/agenda?startDate&endDate',
  comp: () => lazy(() => import('./index')),
  wrapper: AgendaLayout,
  activate: async (_, params) => {
    const { startDate, endDate } = params || {};

    return agendaStore.setParams({
      startDate: fromBrowserDateFormat(startDate!).toISOString(),
      endDate: fromBrowserDateFormat(endDate!).toISOString(),
    });
  },
};
