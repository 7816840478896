import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import type { Locale } from 'date-fns';

import { storeFactory } from '../utils/store';
import { UserApi } from '../api/user';
import { ApiReq, emptyValue } from '../api';
import {
  LocalesResponse,
  UserResponse,
  UserLocaleUpdateResponse,
  UpdateUserLocales,
} from 'src/api/api-types/user';
import {
  supportedDateLocalesMap,
  supportedTimezonesMap,
} from '../utils/locales';

class UserStore {
  api = new UserApi({ prefix: '' });

  @observable.ref userDataReq: ApiReq<UserResponse> = emptyValue;
  @observable.ref
  userUpdateLocalesReq: ApiReq<UserLocaleUpdateResponse> = emptyValue;
  @observable.ref localesReq: ApiReq<LocalesResponse> = emptyValue;
  @observable.ref dateLocale?: Locale;
  @observable userTimezone: string = 'Europe/Paris';

  constructor() {
    makeObservable(this);
  }

  @computed get userData() {
    if (this.userDataReq.state !== 'fulfilled' || !this.userDataReq.value)
      return null;

    return this.userDataReq.value.data;
  }

  @computed get userLocale() {
    if (this.userDataReq.state !== 'fulfilled' || !this.userDataReq.value)
      return null;

    return this.userDataReq.value.data?.profile.locale;
  }

  @computed get locales() {
    if (this.localesReq.state !== 'fulfilled') return [];

    return this.localesReq.value.data;
  }

  @computed get currentId() {
    return this.userData?.profile.key;
  }

  @computed get userLocaleExtended() {
    return (this.locales || []).find(({ id }) => id === this.userLocale?.key);
  }

  @computed get dateLocaleTag() {
    if (!this.userLocaleExtended?.localeTag) {
      throw new Error('User locale is missing');
    }

    const maybeLocale = supportedDateLocalesMap[this.userLocaleExtended.iso2];

    if (!maybeLocale) {
      // eslint-disable-next-line no-console
      console.error(
        `User locale ${this.userLocaleExtended.localeTag} is not supported, using default (en-US) instead`,
      );
    }

    return maybeLocale || 'en-US';
  }

  @action fetchUser = async () => {
    this.userDataReq = this.api.getUserData();

    return this.userDataReq;
  };

  @action updateUserLocale = async (data: UpdateUserLocales) => {
    this.userUpdateLocalesReq = this.api.putLocales(data);
    this.userDataReq = this.api.getUserData();
    await this.userUpdateLocalesReq;
    await this.fetchUser();
  };

  @action fetchLocales = async () => {
    if (!this.locales) {
      //this condition prevent unauthorized first call
      this.localesReq = this.api.getLocalesData();

      await this.localesReq;

      runInAction(async () => {
        const module = await import(`date-fns/locale/${this.dateLocaleTag}`);

        this.dateLocale = module.default;

        if (!this.userLocaleExtended) return;

        this.userTimezone =
          supportedTimezonesMap[this.userLocaleExtended?.iso2];
      });

      return this.localesReq;
    }
  };
}

export const { store: userStore, storeCtx: userStoreCtx } = storeFactory(
  UserStore,
  'user',
);
