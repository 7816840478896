import { menuItems } from '../';

import { AccountInfo } from '@azure/msal-browser';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { Link } from 'src/components/Link';

import Actions from '../Actions';

import { useStyles } from './styles';

interface Props {
  currentAccount: AccountInfo | null;
  onClose: () => void;
}

const initials = (text: string) =>
  text
    .split(' ')
    .map(x => x[0].toUpperCase())
    .join(' ');

export const Menu = ({ currentAccount, onClose }: Props) => {
  const {
    wrapper,
    topMenuBlock,
    text,
    divider,
    iconBlock,
    link,
    actions,
  } = useStyles();

  return (
    <div className={wrapper}>
      <div className={actions}>
        <div className={topMenuBlock}>
          <Avatar>{initials(currentAccount?.name || '')}</Avatar>
          <Typography variant="h4" component="h4">
            {currentAccount?.name}
          </Typography>
        </div>
        <Actions />
      </div>
      <Divider className={divider} />
      <List>
        {menuItems.map(({ title, icon, route, params }) => (
          <Link to={route} key={title} params={params} className={link}>
            <ListItem button onClick={onClose}>
              <ListItemIcon className={iconBlock}>{icon}</ListItemIcon>
              <ListItemText primary={title} className={text} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );
};
