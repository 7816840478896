import { ApiClient } from 'src/api';
import { MonteraKeyType } from 'src/api/api-types';

import {
  TaskResponse,
  TimePeriodResponse,
  TimePeriodStopPayload,
} from './api-types';

export class TaskApi extends ApiClient {
  getTaskDetails(key: MonteraKeyType) {
    return this.requestGet<TaskResponse>(`Tasks/${key}`);
  }

  getCurrentTimePeriod(key: MonteraKeyType) {
    return this.requestGet<TimePeriodResponse>(
      `Tasks/${key}/currentTimePeriod`,
    );
  }

  startTimePeriod(key: MonteraKeyType) {
    return this.requestPost(`Tasks/${key}/timePeriod/start`);
  }

  stopTimePeriod(key: MonteraKeyType, data: TimePeriodStopPayload) {
    return this.requestPost(`Tasks/${key}/timePeriod/stop`, data);
  }
}
