import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { storeFactory } from 'src/utils/store';
import { FilesApi } from 'src/api/files';
import { ApiReq, emptyValue } from 'src/api';
import { ServiceFile, ServiceOrderFile } from 'src/models/file';
import { FilesResponse } from 'src/api/api-types/files';
import { FilesFilterType } from 'src/types';
import { flatMap } from 'lodash';
import { GalleryFile } from 'src/models/file/galery-file';
import { FilesViewTypes } from 'src/types/index';
import { routerStore } from 'mobx-router6';
import { LookupsApi } from 'src/api/lookups';

class FilesStore {
  readonly lookupsApi = new LookupsApi();
  readonly api = new FilesApi();
  @observable.ref filesReq: ApiReq<FilesResponse> = emptyValue;
  @observable filterBy: FilesFilterType = FilesFilterType.AllFiles;
  @observable serviceFiles: IObservableArray<ServiceFile> = observable([]);
  @observable serviceOrderFiles: IObservableArray<ServiceOrderFile> = observable([]);
  @observable taskId: string = '';
  @observable carouselFiles: { [key in FilesFilterType]: GalleryFile[] } = observable({ SOFiles: [], serviceFiles: [], allFiles: [] });
  @observable activateCarousel: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @computed get filesRes() {
    if (this.filesReq.state !== 'fulfilled' || !this.filesReq.value) {
      return null;
    }

    return this.filesReq.value.data?.files;
  }

  @computed get serviceFilesCount() {
    return this.serviceFiles.reduce((acc, cur) => {
      return cur.files.length > 0 ? acc + cur.files.length : 0;
    }, 0);
  }

  @computed get serviceOrderId() {
    return this.filesRes?.serviceOrderId;
  }

  @computed get serviceOrderName() {
    return this.filesRes?.serviceOrderName;
  }

  @action changeFilter = (type: FilesFilterType) => {
    this.filterBy = type;
  };

  @action refetchTaskFiles = () => {
    return this.fetchTaskFiles({ key: this.taskId });
  };

  @action fetchTaskFiles = async ({ key }: { key: string }) => {
    this.filesReq = this.api.getTaskFiles(key);
    this.taskId = key;
    await this.filesReq;

    runInAction(() => {
      if (!this.filesRes) return;

      const { serviceFiles, serviceOrderFiles } = this.filesRes;

      this.serviceFiles.replace(
        serviceFiles.map(file => new ServiceFile(file)),
      );

      this.serviceOrderFiles.replace(
        serviceOrderFiles.map(file => new ServiceOrderFile(file)),
      );
      this.lookupsApi.getFeatureFlags().then(({data}) => {
        if(data?.carouselFiles) {
          const SFiles = flatMap(serviceFiles.map((service) => service.serviceFiles.map(file => new GalleryFile(file, service.serviceName))));
          const SOFiles = serviceOrderFiles.map(file => new GalleryFile(file));
          this.carouselFiles = {
            SOFiles: SOFiles,
            serviceFiles: SFiles,
            allFiles: SOFiles.concat(...SFiles)
          }
          this.loadCarouselFirstImage();
        }
      });
    });
  };

  @action loadCarouselFirstImage = (filterBy?: FilesFilterType) => {
    const firstFile = this.carouselFiles[filterBy || this.filterBy][0];
    if (routerStore.route.params?.view === FilesViewTypes.Carousel && firstFile.filesReq.state !== "pending") {
      firstFile.fetchSrc(); 
    }
  };
}

export const { store: filesStore, storeCtx: filesStoreCtx } = storeFactory(
  FilesStore,
  'files',
);
