import { createContext } from 'react';

import set from 'lodash/set';

interface ExposeOptions {
  path?: string;
}

type Constructable<T> = new (p?: any) => T;

export const exposeGlobally = (test: any, { path }: ExposeOptions = {}) => {
  if (process.env.NODE_ENV === 'production') return;

  if (path) return set(window, `store.${path}`, test);
};

export function storeFactory<S>(Store: Constructable<S>, path: string) {
  const store = new Store();

  exposeGlobally(store, { path });

  return {
    store,
    storeCtx: createContext<S>(store),
  };
}

export type WithStore<Props, S> = Props & { store: S };
