type ParamValueType = string | number | Date | string[];

const prepareValue = (value: ParamValueType) => {
  if (Array.isArray(value)) {
    return value.join('+');
  }

  return value;
};

export function serializeParams<T = Record<string, ParamValueType>>(
  params: T,
): string {
  const keys = Object.keys(params) as (keyof T)[];

  return keys
    .reduce<string[]>((acc, key) => {
      const value = (params[key] as unknown) as ParamValueType;

      if (!value) return acc;

      if (Array.isArray(value) && value.length === 0) {
        return acc;
      }

      return [...acc, `${key} eq ${prepareValue(value)}`];
    }, [])
    .join(',');
}

export const downloadBlob = (blob: Blob | null, filename: string) => {
  if (blob === null) return;
  const url = window.URL.createObjectURL(new Blob([blob]));
  let element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  element.remove();
};
