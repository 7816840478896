import axios, { AxiosResponse } from 'axios';
import { computed } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';

import { authStore } from 'src/stores/auth';
import { globalViewModelStore } from 'src/stores/global-vm';
import { authenticationParameters } from '../config/auth-config';
import { serializeParams } from '../utils/api';
import { FileSignType } from 'src/api/api-types/files';

interface RequestProps<P, D = {}> {
  method: 'put' | 'post' | 'get' | 'delete';
  url: string;
  params?: P;
  data?: D; //AxiosRequestConfig
}

export type ApiReq<T> = IPromiseBasedObservable<AxiosResponse<T | null>>;
export const emptyValue = fromPromise.resolve({ data: null }) as ApiReq<null>;

export class ApiClient {
  private readonly prefix: string;

  constructor({ prefix }: { prefix: string } = { prefix: '/WorkerPortal' }) {
    this.prefix = prefix;
  }

  @computed get headers() {
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${authStore.token}`,
    };
  }

  @computed get headersFile() {
    return {
      ...this.headers,
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${authStore.fileToken}`,
    };
  }

  private get env() {
    return {
      __domain: process.env.REACT_APP_Domain,
      __apiVersion: process.env.REACT_APP_ApiVersion,
      __metaApi: process.env.REACT_APP_MetaApi,
      __fileApi: process.env.REACT_APP_FilesApi,
    };
  }

  private get apiBase() {
    return `${this.env.__domain}/${this.env.__apiVersion}${this.prefix}`;
  }

  private get apiMeta() {
    return `${this.env.__metaApi}/${this.env.__apiVersion}`;
  }

  requestGet<T, P = {}>(url: string, params?: P) {
    return this.requestDataApi<T, P>({ method: 'get', url, params });
  }

  requestDelete<T, P = {}>(url: string, params?: P) {
    return this.requestDataApi<T, P>({ method: 'delete', url, params });
  }

  requestGetMeta<T, P = {}>(url: string, params?: P) {
    return this.requestMetaApi<T, P>({ method: 'get', url, params });
  }

  requestPost<T, D, P = {}>(url: string, data?: D, params?: P) {
    return this.requestDataApi<T, P, D>({ method: 'post', url, data, params });
  }

  requestPut<T, D, P = {}>(url: string, data?: D, params?: P) {
    return this.requestDataApi<T, P, D>({ method: 'put', url, data, params });
  }

  requestPostFiles<T, D, P = {}>(url: string, data?: D, params?: P) {
    const config = { responseType: 'blob' };
    return this.requestDataApi<T, P, D>({
      method: 'post',
      url,
      data,
      params,
      ...config,
    });
  }

  requestFiles<T, P = {}>(url: string, params?: P) {
    const config = { responseType: 'blob' };
    return this.requestFilesApi<T, P>({
      method: 'get',
      url,
      params,
      ...config,
    });
  }

  requestFilesDelete<T, P = {}>(url: string, params?: P) {
    return this.requestFilesApi<T, P>({
      method: 'delete',
      url,
      params,
    });
  }

  requestUploadFile<T>(
    file: File,
    fileSign: FileSignType,
    progressCallback?: (progress: number) => void,
  ) {
    let formData = new FormData();
    formData.append('file', file);
    const config = {
      data: formData,
      onUploadProgress: (p: any) => {
        progressCallback && progressCallback(p.loaded / p.total);
      },
    };
    progressCallback && progressCallback(1);
    return this.requestFilesApi<T>({
      method: 'post',
      url: `files/?fileSign=${fileSign}`,
      ...config,
    });
  }

  private getUrl(url: string) {
    const shouldIgnorePrefix = url.startsWith('$');
    const safeUrl = shouldIgnorePrefix ? url.replace('$', '') : url;

    if (shouldIgnorePrefix) {
      return `${this.env.__domain}/${this.env.__apiVersion}/${safeUrl}`;
    }

    return `${this.apiBase}/${url}`;
  }

  private request<T = {}, P = {}, D = {}>({
    method,
    params,
    url,
    ...config
  }: RequestProps<P, D>): Promise<AxiosResponse<T | null>> {
    return axios
      .request({
        method,
        params,
        url,
        headers: this.headers,
        ...config,
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          authStore.msalInstance.acquireTokenRedirect(authenticationParameters);
        }
        globalViewModelStore.setApiErrors(error?.response?.data?.errors || []);
        throw error;
      });
  }

  private requestDataApi<T = {}, P = {}, D = {}>({
    method,
    url,
    params,
    data,
    ...config
  }: RequestProps<P, D>): ApiReq<T> {
    const filters = ((params as unknown) as { filters?: string })?.filters;

    return fromPromise(
      this.request<T, P, D>({
        method,
        data,
        params: filters
          ? (({ ...params, filters: serializeParams(filters) } as unknown) as P)
          : params,
        url: this.getUrl(url),
        ...config,
      }),
    );
  }

  private requestFilesApi<T = {}, P = {}>({
    method,
    params,
    url,
    ...config
  }: RequestProps<P>): ApiReq<T> {
    const req = fromPromise(
      axios
        .request({
          method,
          params,
          url: `${this.env.__fileApi}/${this.env.__apiVersion}/${url}`,
          headers: this.headersFile,
          ...config,
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            (async () => {
              await authStore.authInFileContextApi();
            })();
          }
          throw error;
        }),
    );
    if (!authStore.fileAccessToken) {
      authStore.authInFileContextApi().then(() => {
        return req;
      });
    }

    return req;
  }

  private requestMetaApi<T = {}, P = {}>({
    method,
    url,
    params,
  }: RequestProps<P>): ApiReq<T> {
    return fromPromise(
      this.request({ method, params, url: `${this.apiMeta}/${url}` }),
    );
  }
}
