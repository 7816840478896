import { FileBase } from './base';
import { ServiceFileType } from 'src/api/api-types/files';

export class ServiceFile {
  readonly files: FileBase[];
  readonly serviceId: number;
  readonly serviceName: string;

  constructor({ serviceFiles, serviceId, serviceName }: ServiceFileType) {
    this.files = serviceFiles.map(file => new FileBase(file));

    this.serviceId = serviceId;
    this.serviceName = serviceName;
  }
}
