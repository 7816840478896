import { lazy } from 'react';

import { MonteraRoute } from 'src/utils/router';

import { taskStore } from '../Task/store';
import { TaskParams } from '../Task/types';

export const EventDetailsRoute: MonteraRoute<'event', Partial<TaskParams>> = {
  name: 'event',
  path: '/event/:id',
  comp: () => lazy(() => import('./index')),
  activate: async (routerStore, params) => {
    if (!params?.id) return;

    if (!params.tab) {
      routerStore?.goTo(
        {
          name: 'event',
          params: { id: params.id},
        },
        { replace: true },
      );
    }

    await taskStore.ensureTaskDetails(params.id);
  },
};
