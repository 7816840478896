import {
  CatalogItem,
  Item,
  LineItemForSaving,
  PriceInfo,
} from 'src/api/api-types/line-items';
import { observable, makeObservable, action, computed } from 'mobx';
import { LineItemTargetType, PriceListItemType } from 'src/types';
import { isInt } from 'src/utils/common';

export class CreateLineItemBase {
  readonly key: string;
  readonly catalogItem: CatalogItem;
  readonly vat: number;
  readonly rot: number;
  readonly priceInfo: PriceInfo;
  readonly priceListItemId?: string;
  readonly lineItemType: LineItemTargetType = LineItemTargetType.Customer;
  readonly priceListItemType?: PriceListItemType;

  readonly lineItemInvoicedId: string = '0';
  readonly sellerId: string = '';

  @observable qty: number = 1;
  @observable comment: string = '';

  constructor({
    key,
    catalogItem,
    vat,
    rot,
    priceInfo,
    priceListItemId,
    priceListItemType,
    qty,
    lineItemInvoicedId,
    comment,
    sellerId,
  }: Item & Partial<LineItemForSaving>) {
    makeObservable(this);

    this.key = key;
    this.catalogItem = catalogItem;
    this.vat = vat;
    this.rot = rot;
    this.priceInfo = priceInfo;

    this.priceListItemId = priceListItemId;
    this.priceListItemType = priceListItemType;

    if (catalogItem.unit.quantity) this.qty = catalogItem.unit.quantity;
    if (qty) this.qty = qty;
    if (lineItemInvoicedId) this.lineItemInvoicedId = lineItemInvoicedId;
    if (comment) this.comment = comment;
    if (sellerId) this.sellerId = sellerId;
  }

  @computed get isValid() {
    if (!this.catalogItem.isDecimalQuantity) {
      return isInt(this.qty);
    }

    return true;
  }

  @action setComment = (comment: string) => {
    this.comment = comment;
  };

  @action setQuantity = (qty: number) => {
    this.qty = qty;
  };

  @action incrementQuantity = () => {
    this.setQuantity(this.qty + 1);
  };
}
