import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const FilterIcon = (props: SvgIconProps) => (
  <SvgIcon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 16"
    {...props}>
    <path
      clipRule="evenodd"
      d="M17 1H1l6.4 7.6v5.2l3.2 1.6V8.6L17 1z"
      stroke="#141E30"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default FilterIcon;
