import { NotFoundRoute } from './pages/404/route';
import { LineItemsRoute } from './pages/AddLineItems/route';
import { AgendaRoute } from './pages/Agenda/route';
import { HelpRoute } from './pages/Help/route';
import { HomepageRoute } from './pages/Home/route';
import { ReportsRoute } from './pages/Reports/route';
import { SupportRoute } from './pages/Support/route';
import { TaskDetailsRoute } from './pages/Task/route';
import { EventDetailsRoute } from './pages/Event/route';
import { TimesheetRoute } from './pages/Timesheet/route';

export const routes = [
  NotFoundRoute,
  HomepageRoute,
  AgendaRoute,
  HelpRoute,
  ReportsRoute,
  SupportRoute,
  TaskDetailsRoute,
  EventDetailsRoute,
  LineItemsRoute,
  TimesheetRoute,
] as const;

export type RoutesUnion = typeof routes[number];
export type RouteName = RoutesUnion['name'];
