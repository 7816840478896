import { LineItem } from './line-item';
import { IObservableArray, makeObservable, observable } from 'mobx';
import { InvoiceType } from 'src/api/api-types/line-items';
import { Task } from '../../../models/task';

export class InvoiceBase {
  readonly name: string;
  readonly subtotal: number;
  readonly subtotalQty: number;
  readonly subtotalVal: number;

  @observable.ref task: Task;
  @observable.ref lineItems: IObservableArray<LineItem> = observable([]);

  constructor({
    name,
    subtotal,
    subtotalQty,
    subtotalVal,
    lineItems,
  }: InvoiceType, task: Task) {
    makeObservable(this);

    this.name = name;
    this.subtotal = subtotal;
    this.subtotalQty = subtotalQty;
    this.subtotalVal = subtotalVal;
    this.task = task;

    this.lineItems.replace(lineItems.map(i => new LineItem(i, this.task)));
  }
}
