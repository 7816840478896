import { ApiClient } from 'src/api';

import { AgendaRequest, AgendaResponse } from './api-types';

export class AgendaApi extends ApiClient {
  getAgenda({ startDate, endDate, filters }: AgendaRequest) {
    return this.requestGet<AgendaResponse, AgendaRequest>('Agenda', {
      startDate,
      endDate,
      filters,
    });
  }
}
