import { AgendaEvent as AgendaEvenType } from '../api-types';
import intervalToDuration from 'date-fns/intervalToDuration';
import { AgendaIntervalType } from 'src/types';
import isFuture from 'date-fns/isFuture';
import { createColorGetter } from 'src/utils/colors';
import isSameDay from 'date-fns/isSameDay';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import { formatDate } from 'src/utils/date';
import { TaskStatus } from 'src/api/api-types/lookups';

const getColor = createColorGetter();

interface Props {
  event: AgendaEvenType;
}

export class AgendaEvent {
  readonly startDate: Date;
  readonly endDate: Date;
  readonly address: string;
  readonly customerId: number;
  readonly customerName: string;
  readonly title: string;
  readonly color: string;
  readonly jobTypeName: string;
  readonly jobTypeId: number;
  readonly taskId: number;
  readonly taskStatusId: number;
  readonly serviceTypeName: string;
  readonly isEvent: boolean;
  readonly notesCount = 0;
  readonly taskStatus: TaskStatus;

  constructor({ event }: Props) {
    const {
      startDate,
      endDate,
      deliveryAddress,
      customerId,
      customerName,
      taskId,
      taskStatusId,
      jobTypeColor,
      jobTypeName,
      jobTypeId,
      serviceTypeName,
      isEvent,
      taskStatus
    } = event;

    this.startDate = new Date(startDate);
    this.endDate = new Date(endDate);
    this.address = deliveryAddress || '';
    this.customerId = customerId;
    this.customerName = customerName || '';
    this.title = jobTypeName || '';
    this.taskId = taskId;
    this.taskStatusId = taskStatusId;
    this.color = jobTypeColor || getColor(jobTypeName);
    this.jobTypeId = jobTypeId;
    this.serviceTypeName = serviceTypeName || '';
    this.isEvent = isEvent || false;
    this.taskStatus = taskStatus;
    this.jobTypeName = jobTypeName;
  }

  get isInFuture() {
    return isFuture(this.startDate);
  }

  get hour() {
    return this.startDate.getHours().toString();
  }

  get startDateFormatted() {
    return formatDate(this.startDate, 'HH:mm');
  }

  get endDateFormatted() {
    return formatDate(this.endDate, 'HH:mm');
  }

  getHeight = (type: AgendaIntervalType) => {
    if (type === 'day') {
      const duration = intervalToDuration({
        start: this.startDate,
        end: this.endDate,
      });

      const { hours = 0, minutes = 0 } = duration;

      const totalMinutes = 60 * hours + minutes;

      return totalMinutes * 1.33 - 4;
    }

    return 'auto';
  };

  getWidth = (size: number) => `${size * 100}%`;
  getLeft = (size: number, order: number) => {
    if (size === 1) return 0;

    return `calc(${size * 100 * order}% + ${order * 3}px)`;
  };

  getStyles = (type: AgendaIntervalType, size: number, order: number) => ({
    width: this.getWidth(size),
    height: this.getHeight(type),
    left: this.getLeft(size, order),
    backgroundColor: this.color,
  });

  getEventStartDateForDay = (dayDate: Date) => {
    if (isSameDay(dayDate, this.startDate)) {
      return this.startDate;
    }

    return startOfDay(dayDate);
  };

  getEventEndDateForDay = (dayDate: Date) => {
    if (isSameDay(dayDate, this.endDate)) {
      return this.endDate;
    }

    return endOfDay(dayDate);
  };

  serializeForDay = (dayDate: Date): AgendaEvenType => {
    return {
      startDate: this.getEventStartDateForDay(dayDate).toISOString(),
      endDate: this.getEventEndDateForDay(dayDate).toISOString(),
      taskId: this.taskId,
      jobTypeColor: this.color,
      jobTypeName: this.jobTypeName,
      customerName: this.customerName,
      deliveryAddress: this.address,
      jobTypeId: this.jobTypeId,
      taskStatusId: this.taskStatusId,
      customerId: this.customerId,
      serviceTypeName: this.serviceTypeName,
      taskStatus: this.taskStatus
      
    };
  };
}
