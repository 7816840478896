import { useContext, useState } from 'react';

import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Toolbar from '@material-ui/core/Toolbar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { observer } from 'mobx-react-lite';
import CalendarIcon2 from 'src/assets/icons/CalendarIcon';
import DownloadIcon from 'src/assets/icons/DownloadIcon';
import DatePicker from 'src/components/DatePicker';
import { GetTimesheetQueryParams } from 'src/pages/Timesheet/api-types';
import { TimesheetRangeType } from 'src/types';

import { timesheetStoreCtx } from '../store';

import { useStyles } from './styles';
import { RouterStoreCtx } from 'mobx-router6';

const options = [
  {
    name: 'Today',
    value: TimesheetRangeType.today,
  },
  {
    name: 'Yesterday',
    value: TimesheetRangeType.yesterday,
  },
  {
    name: 'This week',
    value: TimesheetRangeType.this_week,
  },
  {
    name: 'This month',
    value: TimesheetRangeType.this_month,
  },
  {
    name: 'Last week',
    value: TimesheetRangeType.last_week,
  },
  {
    name: 'Last month',
    value: TimesheetRangeType.last_month,
  },
  {
    name: 'Custom',
    value: TimesheetRangeType.custom,
  },
];

interface Props {
  setTimesheetRange: (range: TimesheetRangeType) => void;
  currentTimesheetRange: TimesheetRangeType;
  download: (taskId: string) => Promise<void>;
  startDate: Date;
  endDate: Date;
  setTimesheetParams: (props: Partial<GetTimesheetQueryParams>) => void;
  addButton?: React.ReactNode;
}

export const LayoutInnerBar = ({
  setTimesheetRange,
  currentTimesheetRange,
  download,
  startDate,
  endDate,
  setTimesheetParams,
  addButton
}: Props) => {
  const { route } = useContext(RouterStoreCtx);
  const [open, setOpen] = useState(false);
  const {
    bar,
    button,
    radio,
    checked,
    radioWrapper,
    pickersContainer,
  } = useStyles();

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleChangeRange = (value: TimesheetRangeType) => {
    setTimesheetRange(value);
    toggleOpen();
  };

  const handleDownload = () => {
    download(route.params.id);
  };

  const currentOptionSelected = options.find(
    ({ value }) => value === currentTimesheetRange,
  );

  return (
    <Toolbar className={bar}>
      {addButton}
      <Button
        variant="text"
        color="primary"
        className={button}
        startIcon={<CalendarIcon2 color="primary" />}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        onClick={toggleOpen}>
        {currentOptionSelected?.name}
      </Button>
      <IconButton>
        <DownloadIcon fontSize="small" onClick={handleDownload} />
      </IconButton>
      <Drawer anchor="bottom" open={open} onClose={toggleOpen}>
        <RadioGroup value={currentTimesheetRange}>
          <List>
            {options.map(({ name, value }) => (
              <ListItem
                button
                key={value}
                component="button"
                onClick={() => handleChangeRange(value)}>
                <ListItemText>{name}</ListItemText>
                <ListItemSecondaryAction>
                  <FormControlLabel
                    value={value}
                    label=""
                    className={radioWrapper}
                    control={
                      <Radio
                        name="radio-interval"
                        classes={{ root: radio, checked }}
                      />
                    }
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </RadioGroup>
        <div className={pickersContainer}>
          <DatePicker
            label="From"
            value={startDate.toISOString()}
            disableKeyboard
            onChange={value => {
              if (value) setTimesheetParams({ startDate: value });
            }}
            fullWidth
          />
          <DatePicker
            label="To"
            value={endDate.toISOString()}
            disableKeyboard
            onChange={value => {
              if (value) setTimesheetParams({ endDate: value });
            }}
            fullWidth
          />
        </div>
      </Drawer>
    </Toolbar>
  );
};

const WithStore = () => {
  const {
    setTimesheetRange,
    currentTimesheetRange,
    download,
    startDate,
    endDate,
    setTimesheetParams,
  } = useContext(timesheetStoreCtx);

  return (
    <LayoutInnerBar
      setTimesheetRange={setTimesheetRange}
      currentTimesheetRange={currentTimesheetRange}
      download={download}
      startDate={startDate}
      endDate={endDate}
      setTimesheetParams={setTimesheetParams}
    />
  );
};

export default observer(WithStore);
