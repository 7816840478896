import { lazy, PropsWithChildren } from 'react';

import { AccountInfo } from '@azure/msal-browser';
import { RouteName } from 'src/routes';
import { ResponsiveWrapper } from 'src/utils/screen';

interface Props {
  currentAccount: AccountInfo | null;
  currentRoute: RouteName;
  LayoutInnerBar?: () => JSX.Element | null;
}

export default ({
  children,
  currentAccount,
  currentRoute,
  LayoutInnerBar,
}: PropsWithChildren<Props>) => (
  <ResponsiveWrapper<Props>
    getLazyDesktopComponent={() => lazy(() => import('./desktop'))}
    getLazyMobileComponent={() => lazy(() => import('./mobile'))}
    innerProps={{ currentAccount, currentRoute, LayoutInnerBar }}>
    {children}
  </ResponsiveWrapper>
);
