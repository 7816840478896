import { TimesheetStore } from 'src/pages/Timesheet/store';
import { TimesheetApi } from 'src/pages/Timesheet/api';
import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  override,
  reaction,
  runInAction,
} from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import { TimePeriod } from 'src/pages/Timesheet/models/time-period';
import {
  formatMinutesDuration,
  getWeekRangeByStartDate,
  removeTimezoneEndOfDay,
} from 'src/utils/date';
import { taskStore } from '../../store';
import head from 'lodash/head';
import last from 'lodash/last';
import eachWeekOfInterval from 'date-fns/eachWeekOfInterval';
import isWithinInterval from 'date-fns/isWithinInterval';
import add from 'date-fns/add';
import { storeFactory } from 'src/utils/store';
import { lookupsStore } from 'src/stores/lookups';
import { AddTimeReportParams, AddTimeReportResponse, TimePeriodsResponse } from 'src/pages/Timesheet/api-types';
import {  startOfDay } from 'date-fns';

export class TaskTimeperiodsStore extends TimesheetStore {
  readonly timesheetApi = new TimesheetApi();
  @observable.ref timeperiodsReq: ApiReq<TimePeriodsResponse> = emptyValue;
  @observable.ref addTimeReportReq: ApiReq<AddTimeReportResponse> = emptyValue;
  @observable.ref timeperiods: IObservableArray<TimePeriod> = observable([]);

  constructor() {
    super({ isExtended: true });
    makeObservable(this);
    reaction(() => [this.startDate, this.endDate], this.fetchPeriods, {
      name: 'TaskTimeperiodsStore.ChangedParams',
    });
  }

  
  @computed get timeperiodsRes() {
    if (
      this.timeperiodsReq.state !== 'fulfilled' ||
      !this.timeperiodsReq.value
    ) {
      return null;
    }

    return this.timeperiodsReq.value.data?.taskTimePeriods;
  }

  @computed get addTimeReportResStatus() {
    if(this.addTimeReportReq.state !== 'fulfilled') {
      return null;
    }
    return this.addTimeReportReq.value.status;
  }

  @computed get timeperiodsMapByDay() {
    return this.timeperiods.reduce<Record<string, TimePeriod[]>>(
      (acc, timeperiod) => {
        return {
          ...acc,
          [timeperiod.dateDay]: [
            ...(acc[timeperiod.dateDay] || []),
            timeperiod,
          ],
        };
      },
      {},
    );
  }

  @computed get timeperiodsByDay() {
    return Object.values(this.timeperiodsMapByDay);
  }

  @computed get timeperiodsMapByWeek() {
    const start = head(this.timeperiods)?.dateDay;
    const end = last(this.timeperiods)?.dateDay;

    const weeks = eachWeekOfInterval(
      {
        start: start ? new Date(start) : new Date(),
        end: end ? new Date(end) : new Date(),
      },
      { weekStartsOn: 1 },
    );

    return weeks.reduce((acc, weekStart) => {
      const days = Object.keys(this.timeperiodsMapByDay).filter(day => {
        return isWithinInterval(new Date(day), {
          start: weekStart,
          end: add(weekStart, { weeks: 1 }),
        });
      });
      if (days.length === 0) return acc;

      const daysMap = days.reduce(
        (acc, day) => ({
          ...acc,
          [day]: this.timeperiodsMapByDay[day],
        }),
        {} as Record<string, TimePeriod[]>,
      );

      return {
        ...acc,
        [weekStart.toISOString()]: daysMap,
      };
    }, {} as Record<string, Record<string, TimePeriod[]>>);
  }

  @computed get timeperiodsWeeks() {
    return Object.keys(this.timeperiodsMapByWeek).map(weekStart => {
      const totalWeekMinutes = Object.values(
        this.timeperiodsMapByWeek[weekStart],
      )
        .reduce((acc, item) => [...acc, ...item], [] as TimePeriod[])
        .reduce((acc, item) => {
          return acc + item.totalMinutes;
        }, 0);

      return {
        range: getWeekRangeByStartDate(new Date(weekStart)),
        timeperiods: Object.values(this.timeperiodsMapByWeek[weekStart]),
        total: formatMinutesDuration(totalWeekMinutes),
      };
    });
  }

  @override get totalDuration() {
    const totalMinutes = Object.values(this.timeperiodsMapByDay)
      .reduce((acc, item) => [...acc, ...item], [] as TimePeriod[])
      .reduce((acc, item) => {
        return acc + item.totalMinutes;
      }, 0);

    return formatMinutesDuration(totalMinutes);
  }
  
  @action fetchPeriods = async () => {
    if (!taskStore.task) {
      return;
    }

    this.timeperiodsReq = this.timesheetApi.getTimeperiodsByRange({
      taskId: taskStore.task.id,
      startDate: startOfDay(this.startDate).toISOString(),
      endDate: removeTimezoneEndOfDay(this.endDate.toISOString()),
      pageSize: 999,
    });

    await lookupsStore.ensureJobTypes();
    await this.timeperiodsReq;

    runInAction(() => {
      if (this.timeperiodsRes) {
        this.timeperiods.replace(
          this.timeperiodsRes.map(
            tp => new TimePeriod({ ...tp, jobTypes: lookupsStore.jobTypes }),
          ),
        );
      }
    });
  };

  @action addTimeReport = async (timesheet: AddTimeReportParams) => {
    this.addTimeReportReq = this.timesheetApi.addTimeReport(timesheet);
    await this.addTimeReportReq;
    runInAction(() => {
      if(this.addTimeReportResStatus === 200) {
        this.fetchPeriods();
        this.addTimeReportReq = emptyValue
      }
    });
  }
}

export const {
  store: taskTimePeriodsStore,
  storeCtx: taskTimePeriodsStoreCtx,
} = storeFactory(TaskTimeperiodsStore, 'taskTimeperiods');
