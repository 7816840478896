import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    bar: {
      minHeight: 48,
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: '45px 1fr 45px',
    },
    popper: {
      position: 'relative',
      minWidth: 180,
      '& > button': {
        width: '100%',
      },
    },
    button: {
      color: theme.palette.primary.dark,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& svg': {
        position: 'relative',
        top: -2,
      },
    },
    radio: {
      '&$checked': {
        color: '#2B59C3 !important',
      },
    },
    checked: {},
    radioWrapper: {
      marginRight: 0,
      '& > .MuiRadio-root': {
        padding: 0,
      },
    },
    pickersContainer: {
      padding: '0 12px',
    },
  }),
);
