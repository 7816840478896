import {
  ComponentType,
  Suspense,
  useEffect,
  useState,
  LazyExoticComponent,
  PropsWithChildren,
} from 'react';

import debounce from 'lodash/debounce';

interface WindowSizeState {
  width?: number;
  height?: number;
}

export const desktopBreakpoint = 600;

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSizeState>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', debounce(handleResize, 100));

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

type MediaType = 'mobile' | 'desktop';

export const useMedia = ({ type }: { type: MediaType }) => {
  const { width } = useWindowSize();

  if (!width) return false;

  switch (type) {
    case 'mobile':
      return width < desktopBreakpoint;
    case 'desktop':
      return width >= desktopBreakpoint;
    default:
      throw new Error(
        `Invalid usage of useMedia, supported media types are: [mobile, desktop], but received: ${type}`,
      );
  }
};

interface Props<P> {
  getLazyDesktopComponent: () => LazyExoticComponent<any>;
  getLazyMobileComponent: () => LazyExoticComponent<any>;
  innerProps?: P;
}

export function ResponsiveWrapper<P extends object>({
  getLazyDesktopComponent,
  getLazyMobileComponent,
  children,
  innerProps,
}: PropsWithChildren<Props<P>>) {
  const isMobile = useMedia({ type: 'mobile' });
  const [Component, setComponent] = useState<ComponentType<any> | null>(null);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // TODO remove this after desktop version is supported
      return setComponent(getLazyMobileComponent());
    }

    if (isMobile) return setComponent(getLazyMobileComponent());

    setComponent(getLazyDesktopComponent());
  }, [isMobile, getLazyDesktopComponent, getLazyMobileComponent]);

  return (
    <Suspense fallback={null}>
      {Component && <Component {...innerProps}>{children}</Component>}
    </Suspense>
  );
}
