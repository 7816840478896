import {
  Category,
  FileQuestionnaireEntity,
  UpdateQuestionnairePayload,
  UpdateQuestionnaireResponse,
} from '../api-types';
import { prettifyItemDate } from 'src/utils/date';
import { action, computed, observable, makeObservable } from 'mobx';
import { QuestionnairesApi } from '../api';
import { ApiReq, emptyValue } from 'src/api';
import { FilesApi } from 'src/api/files';

export class QuestionnaireFile {
  readonly filesApi = new QuestionnairesApi();
  readonly api = new FilesApi();

  readonly id: number;
  readonly name: string;
  readonly serviceId: number;
  readonly categoryId: number;
  readonly category: Category;
  readonly fileServiceId: number;
  readonly description: string;
  readonly createdDate: string;
  readonly isImage: boolean;
  readonly isPDF: boolean;
  readonly isValid: boolean;
  readonly isSharedToPartner: boolean;
  readonly createdBy: number | string;
  readonly createdByName: string;
  readonly partnerId: string;
  readonly partnerName: string;
  readonly relatedEntity?: string;

  @observable.ref updateReq: ApiReq<UpdateQuestionnaireResponse> = emptyValue;
  @observable.ref filesReq: ApiReq<Blob> = emptyValue;
  @observable.ref deleteReq: ApiReq<any> = emptyValue;
  @observable.ref deleteFromServiceReq: ApiReq<any> = emptyValue;

  constructor(props: FileQuestionnaireEntity, relatedEntity?: string) {
    makeObservable(this);
    this.id = props.id || 0;
    this.name = props.name || '';
    this.serviceId = props.serviceId || 0;
    this.categoryId = props.categoryId || 0;
    this.category = props.category || { key: 0, displayText: '' };
    this.fileServiceId = props.fileServiceId || 0;
    this.description = props.description || '';
    this.createdDate = props.createdDate || '';
    this.isImage = props.isImage;
    this.isPDF = props.isPDF;
    this.isValid = props.isValid;
    this.isSharedToPartner = props.isSharedToPartner;
    this.createdBy = props.createdBy || 0;
    this.createdByName = props.createdByName || '';
    this.partnerId = props.partnerId || '';
    this.partnerName = props.partnerName || '';
    this.relatedEntity = relatedEntity;
  }

  get createdDatePrettified() {
    return prettifyItemDate(this.createdDate);
  }

  @computed get src() {
    if (this.filesReq.state === 'fulfilled' && !!this.filesReq.value?.data) {


      return window.URL.createObjectURL(
        new Blob([this.filesReq.value.data as Blob]),
      );
    }

    return '';
  }
  @computed get getPdf() {
    if (this.filesReq.state === 'fulfilled' && !!this.filesReq.value?.data) {
      return window.URL.createObjectURL(
        new Blob([this.filesReq.value.data as Blob], { type: 'application/pdf'}),
      );
    }

    return '';
  }

  @action fetchSrc = async () => {
    if (!!this.src) return;

    this.filesReq = this.api.getFilesUrl(this.fileServiceId);

    await this.filesReq;
  };

  @action update = (patch: UpdateQuestionnairePayload) => {
    this.updateReq = this.filesApi.update(this.id, patch);
    return this.updateReq;
  };

  @action delete = () => {
    this.deleteReq = this.filesApi.delete(this.id);
    return Promise.allSettled([this.deleteReq, this.deleteFromServiceReq]);
  };

  @action download = async () => {
    await this.fetchSrc();
    const element = document.createElement('a');
    element.setAttribute('href', this.src);
    element.setAttribute('download', this.name);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    element.remove();
  };
}
