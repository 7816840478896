import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
} from 'mobx';

import { storeFactory } from 'src/utils/store';
import { LineItemsApi } from 'src/api/line-items';
import { ApiReq, emptyValue } from 'src/api';
import {
  PriceCatalogResponse,
  LineItemsSaveResponse,
} from 'src/api/api-types/line-items';
import { routerStore } from 'src/stores/router';
import { ViewLineItemType } from 'src/types';
import { taskStore } from 'src/pages/Task/store';
import { ContractorLineItem } from './models/contractor';
import { ExternalLineItem } from './models/external';
import { WriteInLineItem } from './models/write-in';

export type SelectedLineItemUnion =
  | ContractorLineItem
  | ExternalLineItem
  | WriteInLineItem;

class LineItemsStore {
  apiLineItems = new LineItemsApi();

  @observable.ref saveLineItemReq: ApiReq<LineItemsSaveResponse> = emptyValue;
  @observable.ref
  priceCatalogReq: ApiReq<PriceCatalogResponse[]> = emptyValue;
  @observable.ref writeInPricesReq: ApiReq<PriceCatalogResponse[]> = emptyValue;

  @observable.ref
  selected: IObservableArray<SelectedLineItemUnion> = observable([]);

  constructor() {
    makeObservable(this);
  }

  @computed get priceCatalog() {
    if (
      this.priceCatalogReq.state !== 'fulfilled' ||
      !this.priceCatalogReq.value
    )
      return [];

    return this.priceCatalogReq.value.data;
  }

  @computed get pricesCatalogWriteIn() {
    if (
      this.writeInPricesReq.state !== 'fulfilled' ||
      !this.writeInPricesReq.value ||
      this.writeInPricesReq?.value?.data === null
    )
      return [];

    return this.writeInPricesReq?.value?.data;
  }

  @computed get priceCatalogForTable() {
    if (!!this.priceCatalog && this.priceCatalog.length > 0) {
      return this.priceCatalog[0].productCategoryItems;
    }

    return [];
  }

  @computed get selectedExternalItems() {
    return this.selected.filter(x => x instanceof ExternalLineItem);
  }

  @computed get selectedWriteInItems() {
    return this.selected.filter(x => x instanceof WriteInLineItem);
  }

  @computed get selectedContractorItems() {
    return this.selected.filter(x => x instanceof ContractorLineItem);
  }

  @computed get isSelectedItemsValid() {
    return this.selected.every(li => li.isValid);
  }

  @action clearSelected = () => this.selected.clear();

  @action saveLineItems = async () => {
    if (!taskStore.task?.taskDetails.taskId) return;

    this.saveLineItemReq = this.apiLineItems.saveLineItem(
      taskStore.task.taskDetails.taskId,
      this.selected.map(lineItem => lineItem.serialized),
    );

    await this.saveLineItemReq;

    if (this.saveLineItemReq.state === 'fulfilled') {
      routerStore?.goTo({
        name: 'add-line-items',
        params: { view: ViewLineItemType.Success },
      });

      this.clearSelected();
    }
  };

  @action fetchWriteInPrices = (partnerId: number) => {
    if (!!this.pricesCatalogWriteIn && this.pricesCatalogWriteIn.length > 0)
      return;

    this.writeInPricesReq = this.apiLineItems.getWriteInPrice(partnerId);
  };

  @action fetchPriceCatalog = async (partnerId: number) => {
    this.priceCatalogReq = this.apiLineItems.getExternalPriceCatalog(partnerId);
  };

  @action fetchContractorPrices = async (contractorId: number) => {
    this.priceCatalogReq = this.apiLineItems.getContractorPriceCatalog(
      contractorId,
    );
  };

  @action selectItem = (lineItem: SelectedLineItemUnion) => {
    const maybeExistingItem = this.selected.find(
      ({ key }) => key === lineItem.key,
    );

    if (maybeExistingItem) {
      return maybeExistingItem.incrementQuantity();
    }

    this.selected.push(lineItem);
  };

  @action resetPriceCatalog = () => (this.priceCatalogReq = emptyValue);
}

export const {
  store: lineItemsStore,
  storeCtx: lineItemsStoreCtx,
} = storeFactory(LineItemsStore, 'lineItems');
