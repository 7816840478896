// locale matching, where key = montera locale name; value = date-fns locale code

export const supportedDateLocalesMap: Record<string, string> = {
  nb: 'nb',
  sv: 'sv',
  da: 'da',
  fi: 'fi',
  en: 'en-GB',
};

export const supportedTimezonesMap: Record<string, string> = {
  nb: 'Europe/Oslo',
  sv: 'Europe/Stockholm',
  da: 'Europe/Copenhagen',
  fi: 'Europe/Helsinki',
  en: 'Europe/Paris',
};
