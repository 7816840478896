import { ApiClient } from 'src/api';
import {
  DownloadParams,
  GetTimePeriodsRangeParams,
  GetTimesheetQueryParams,
  TimePeriodsResponse,
  TimesheetResponse,
  AddTimeReportParams,
  AddTimeReportResponse
} from 'src/pages/Timesheet/api-types';

export class TimesheetApi extends ApiClient {
  getTimesheet(params: GetTimesheetQueryParams) {
    return this.requestGet<TimesheetResponse, GetTimesheetQueryParams>(
      `Timesheet/Timesheet`,
      params,
    );
  }

  downloadTimesheet(params: DownloadParams) {
    return this.requestPostFiles<Blob, DownloadParams>(
      `Timesheet/workers/details/export`,
      params,
    );
  }

  getTimeperiodsByRange({ taskId, ...params }: GetTimePeriodsRangeParams) {
    return this.requestGet<TimePeriodsResponse, GetTimesheetQueryParams>(
      `Tasks/${taskId}/timePeriods/Range`,
      params,
    );
  }

  addTimeReport(params: AddTimeReportParams) {
    return this.requestPost<AddTimeReportResponse, AddTimeReportParams>(`Timesheet`, params);
  }
}
