import { useContext } from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { authStoreCtx } from 'src/stores/auth';

import { MonteraLogo } from '../../MonteraLogo';

import { useStyles } from './styles';

const AuthError = () => {
  const classes = useStyles();
  const { msalInstance } = useContext(authStoreCtx);

  const handleLogout = () => {
    msalInstance.logoutRedirect();
  };

  return (
    <div className={classes.root}>
      <MonteraLogo className={classes.logo} fill="#2B59C3" />
      <Typography variant="h3" component="h3">
        Access Denied
      </Typography>
      <Typography variant="h4" component="h4">
        Contact MonteraPlus administrator
      </Typography>
      <Button
        color="primary"
        variant="contained"
        size="large"
        onClick={handleLogout}>
        Logout
      </Button>
    </div>
  );
};

export default AuthError;
