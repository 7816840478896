import { lazy } from 'react';

import { taskStore } from 'src/pages/Task/store';
import { ViewLineItemType } from 'src/types';
import { MonteraRoute } from 'src/utils/router';

import { lookupsStore } from '../../stores/lookups';
import { userStore } from '../../stores/user';

import HeaderWrapper from './HeaderWrapper';
import { lineItemsStore } from './store';
import { PriceCatalogTabsParams, PriceCatalogType } from './types';

export const LineItemsRoute: MonteraRoute<
  'add-line-items',
  Partial<PriceCatalogTabsParams>
> = {
  name: 'add-line-items',
  path: '/task/:id/add-line-items/add?view&tab',
  comp: () => lazy(() => import('./index')),
  activate: async (routerStore, params) => {
    if (
      !params?.tab ||
      !Object.values(PriceCatalogType).includes(params?.tab as PriceCatalogType)
    ) {
      routerStore?.goTo(
        {
          name: 'add-line-items',
          params: { id: params?.id, tab: PriceCatalogType.External },
        },
        { replace: true },
      );
    }

    if (
      !params?.view ||
      !Object.values(ViewLineItemType).includes(
        params?.view as ViewLineItemType,
      )
    ) {
      routerStore?.goTo(
        {
          name: 'add-line-items',
          params: { id: params?.id, view: ViewLineItemType.Available },
        },
        { replace: true },
      );
    }

    if (!taskStore.task?.partnerInfo.partnerId) {
      await taskStore.fetchTaskDetails(params?.id!);
    }

    const { partnerId } = taskStore.task?.partnerInfo || {};
    const contractorId = userStore.userData?.profile.contractorCompanyId;

    switch (params?.tab) {
      case PriceCatalogType.Contractor:
        if (!contractorId) {
          return lineItemsStore.resetPriceCatalog();
        }

        return lineItemsStore.fetchContractorPrices(contractorId);
      case PriceCatalogType.External:
        if (!partnerId) {
          return lineItemsStore.resetPriceCatalog();
        }

        return lineItemsStore.fetchPriceCatalog(partnerId);
      case PriceCatalogType.WriteIn:
        if (!partnerId) {
          return;
        }

        await lookupsStore.ensureUnits(partnerId);

        return lineItemsStore.fetchWriteInPrices(partnerId);
      default:
        return;
    }
  },
  wrapper: HeaderWrapper,
};
