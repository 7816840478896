import { lazy } from 'react';

import { TimesheetRouteParams } from 'src/types';
import { today } from 'src/utils/date';
import { MonteraRoute, WithLayoutInnerBar } from 'src/utils/router';

import LayoutInnerBar from './LayoutInnerBar';
import { timesheetStore } from './store';

const defaultParams = {
  ...today,
  pageSize: '10',
};

export const TimesheetRoute: WithLayoutInnerBar<
  MonteraRoute<'timesheet', TimesheetRouteParams>
> = {
  name: 'timesheet',
  path: '/timesheet?startDate&endDate&pageSize',
  comp: () => lazy(() => import('./index')),
  activate: async (routerStore, params) => {
    const { pageSize, endDate, startDate } = params || {};

    if (!pageSize || !endDate || !startDate) {
      return routerStore?.goTo(
        {
          name: 'timesheet',
          params: defaultParams,
        },
        { replace: true },
      );
    }

    timesheetStore.setTimesheetParams({
      startDate,
      endDate,
      pageSize: parseFloat(pageSize),
    });
  },
  layoutInnerBar: LayoutInnerBar,
};
