import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    minWidth: 300,
  },
  topMenuBlock: {
    padding: 16,

    '& > h4': {
      paddingTop: 16,
      color: 'white',
    },
  },
  paper: {
    backgroundColor: theme.palette.brand.dark,
  },
  text: {
    color: 'white',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  logoutAction: {
    display: 'flex',
    alignItems: 'flex-end',
    color: 'white',
    padding: '20px 12px',
    fontSize: 18,
    textTransform: 'capitalize'
  },
  divider: {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
  },
  iconBlock: {
    minWidth: 32,
  },
  link: {
    textDecoration: 'none',
  },
}));
