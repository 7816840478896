import { ApiClient } from './';
import {
  LocalesResponse,
  UserResponse,
  UpdateUserLocales,
  UserLocaleUpdateResponse,
} from './api-types/user';

export class UserApi extends ApiClient {
  getUserData() {
    return this.requestGet<UserResponse>(`WorkerPortal/Profile/login`);
  }

  getLocalesData() {
    return this.requestGetMeta<LocalesResponse>(`lookups/locales`);
  }
  putLocales(data: UpdateUserLocales) {
    return this.requestPut<UserLocaleUpdateResponse, UpdateUserLocales>(
      `WorkerPortal/Profile/locale/`,
      data,
    );
  }
}
