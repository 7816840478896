import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { AgendaApi } from './api';
import { storeFactory } from '../../utils/store';
import { AgendaIntervalType } from '../../types';
import isToday from 'date-fns/isToday';
import isSameDay from 'date-fns/isSameDay';
import { ApiReq, emptyValue } from '../../api';
import { AgendaResponse } from './api-types';
import { withoutEmptyValues } from '../../utils/common';
import differenceInDays from 'date-fns/differenceInDays';
import { AgendaEvent } from './models/agenda';
import {
  currentMonth,
  currentWeek,
  removeTimezoneEndOfDay,
  removeTimezoneStartOfDay,
  toBrowserDateFormat,
} from '../../utils/date';
import { routerStore } from '../../stores/router';
import { NavigationOptions } from 'router5';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import subWeeks from 'date-fns/subWeeks';
import addWeeks from 'date-fns/addWeeks';
import subMonths from 'date-fns/subMonths';
import addMonths from 'date-fns/addMonths';

const filters = {
  customerId: '',
  serviceOrderId: '',
  jobTypeId: '',
  taskStatusId: '',
  isEvent: '',
  ...currentMonth,
};

type Filters = Record<keyof typeof filters, string | number>;

interface RouteParams {
  startDate?: string;
  endDate?: string;
}

class AgendaStore {
  api = new AgendaApi();
  @observable agendaIntervalType: AgendaIntervalType = 'month';
  @observable isFilterOpen = false;
  @observable filters: Filters = filters;
  @observable.ref req: ApiReq<AgendaResponse> = emptyValue;
  @observable.ref events = observable<AgendaEvent>([]);

  constructor() {
    makeObservable(this);

    reaction(
      () => [this.filters.startDate, this.filters.endDate],
      ([startDate, endDate]) => {
        if (!startDate || !endDate) {
          return;
        }

        if (this.agendaRangeInDays > 7) {
          return (this.agendaIntervalType = 'month');
        }

        if (this.agendaRangeInDays > 0) {
          return (this.agendaIntervalType = 'week');
        }

        this.agendaIntervalType = 'day';
      },
    );
  }

  @computed get agendaRangeInDays() {
    return differenceInDays(new Date(this.endDate), new Date(this.startDate));
  }

  @computed get agenda() {
    if (this.req.state !== 'fulfilled') {
      return [];
    }

    return this.req.value.data?.agenda || [];
  }

  @computed get filterParams() {
    const { startDate, endDate, ...filters } = this.filters;

    return withoutEmptyValues(filters);
  }

  @computed get calendarFormat() {
    if (this.agendaIntervalType === 'day') {
      return 'iii d';
    }

    return 'LLL yyyy';
  }

  @computed get startDate() {
    return new Date(
      new Date(this.filters.startDate).toISOString(),
    );
  }

  @computed get endDate() {
    return new Date(
      new Date(this.filters.endDate).toISOString(),
    );
  }

  @computed get isToday() {
    return isSameDay(this.startDate, this.endDate) && isToday(this.startDate);
  }

  @action setFilterValue = (partialFilterUpdate: Partial<Filters>) => {
    const keys = Object.keys(partialFilterUpdate) as (keyof Filters)[];
    keys.forEach(key => {
      this.filters[key] = partialFilterUpdate[key]!;
    });
  };

  @action setAgendaIntervalType = (type: AgendaIntervalType) => {
    this.agendaIntervalType = type;

    if (this.agendaIntervalType === 'day' && this.agendaRangeInDays > 0) {
      this.setFilterValue({ endDate: this.filters.startDate });
    }

    if (this.agendaIntervalType === 'week' && this.agendaRangeInDays !== 7) {
      this.goToCurrentWeek();
    }

    if (this.agendaIntervalType === 'month') {
      this.goToCurrentMonth();
    }

    this.syncRouteParams();
  };

  @action setFilterOpen = (isFilterOpen: boolean) => {
    this.isFilterOpen = isFilterOpen;
  };

  @action fetchAgenda = async () => {
    const startDate = removeTimezoneStartOfDay(this.startDate.toISOString());
    const endDate = removeTimezoneEndOfDay(this.endDate.toISOString());

    runInAction(() => {
      this.req = this.api.getAgenda({
        startDate,
        endDate,
        filters: this.filterParams,
      });
    });

    await this.req;

    runInAction(() => {
      this.events.replace(
        this.agenda.map(event => {
          return new AgendaEvent({ event });
        }),
      );
    });
  };

  @action resetFilters = () => {
    this.setFilterValue({
      taskStatusId: '',
      jobTypeId: '',
      serviceOrderId: '',
      customerId: '',
      endDate: currentMonth.endDate,
      startDate: currentMonth.startDate,
    });
  };

  @action setToDate = (date: string) => {
    this.filters.startDate = date;
    this.filters.endDate = date;

    this.syncRouteParams();
  };

  @action setToRange = ([startDate, endDate]: Date[]) => {
    this.filters.startDate = startDate.toISOString();
    this.filters.endDate = endDate.toISOString();

    this.syncRouteParams();
  };

  @action goToCurrentWeek = () => {
    this.setToRange(Object.values(currentWeek).map(x => new Date(x)));
  };

  @action goToCurrentMonth = () => {
    this.setToRange(Object.values(currentMonth).map(x => new Date(x)));
  };

  @action setParams = ({ startDate, endDate }: RouteParams) => {
    if (startDate) {
      this.setFilterValue({ startDate });
    }

    if (endDate) {
      this.setFilterValue({ endDate });
    }

    return this.fetchAgenda();
  };

  @action goPreviousRange = () => {
    switch (this.agendaIntervalType) {
      case 'day':
        return this.setToDate(subDays(this.startDate, 1).toISOString());
      case 'week':
        return this.setToRange([
          subWeeks(this.startDate, 1),
          subWeeks(this.endDate, 1),
        ]);
      case 'month':
        return this.setToRange([
          subMonths(this.startDate, 1),
          subMonths(this.endDate, 1),
        ]);
    }
  };

  @action goNextRange = () => {
    switch (this.agendaIntervalType) {
      case 'day':
        return this.setToDate(addDays(this.startDate, 1).toISOString());
      case 'week':
        return this.setToRange([
          addWeeks(this.startDate, 1),
          addWeeks(this.endDate, 1),
        ]);
      case 'month':
        return this.setToRange([
          addMonths(this.startDate, 1),
          addMonths(this.endDate, 1),
        ]);
    }
  };

  navigateTo = (params: RouteParams, options?: NavigationOptions) => {
    routerStore?.goTo(
      {
        name: 'agenda',
        params,
      },
      options,
    );
  };

  syncRouteParams = () => {
    this.navigateTo({
      startDate: toBrowserDateFormat(new Date(this.startDate)),
      endDate: toBrowserDateFormat(new Date(this.endDate)),
    });
  };
}

export const { store: agendaStore, storeCtx: agendaStoreCtx } = storeFactory(
  AgendaStore,
  'agenda',
);
