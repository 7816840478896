import compose from 'lodash/fp/compose';
import omitBy from 'lodash/fp/omitBy';
import isInteger from 'lodash/isInteger';
import toNumber from 'lodash/toNumber';

const isEmpty = (value?: any): boolean => {
  if (value === undefined) return true;
  if (value === null) return true;

  if (typeof value === 'string') {
    return value.trim() === '';
  }

  return false;
};

export const isInt = compose(isInteger, toNumber);

export const withoutEmptyValues = omitBy(isEmpty);
