import { ViewLineItemType } from 'src/types';

export enum PriceCatalogType {
  External = 'external',
  WriteIn = 'writeIn',
  Contractor = 'contractor',
}

export enum TaskDetailsTabs {
  LineItems = 'line-items',
  // ServiceFlow = 'service-flow',
  Files = 'files',
  Questionnaire = 'questionnaire',
  Comments = 'comments',
  Timereport = 'timereport',
}

export interface PriceCatalogTabsParams {
  id: string;
  tab: PriceCatalogType;
  view: ViewLineItemType;
}
