import format from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import endOfDay from 'date-fns/endOfDay';
import endOfWeek from 'date-fns/endOfWeek';
import formatFp from 'date-fns/fp/format';
import parseFp from 'date-fns/fp/parse';
import isSameMonth from 'date-fns/isSameMonth';
import isThisWeek from 'date-fns/isThisWeek';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import parseISO from 'date-fns/parseISO';
import startOfDay from 'date-fns/startOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import { userStore } from 'src/stores/user';

export const formatDate = (date: Date | number, dateformat: string) =>
  format(date, dateformat, {
    weekStartsOn: 1,
    locale: userStore.dateLocale,
    timeZone: userStore.userTimezone,
  });

export const removeUserTimezone = (date: Date) => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;

  return new Date(date.getTime() - userTimezoneOffset);
};

export const formatMinutesDuration = (minutes: number) => {
  if (minutes > 60) {
    const hoursInt = Math.floor(minutes / 60);
    const minutesInt = minutes % 60;

    return `${hoursInt
      .toString()
      .padStart(2, '0')}h:${minutesInt.toString().padStart(2, '0')}m`;
  }

  return `00h:${minutes.toString().padStart(2, '0')}m`;
};

export const removeTimezoneStartOfDay = (date: string) =>
  removeUserTimezone(startOfDay(new Date(date))).toISOString();

export const removeTimezoneEndOfDay = (date: string) =>
  removeUserTimezone(endOfDay(new Date(date))).toISOString();

export const getWeekRange = (startDate: Date, endDate: Date) => {
  if (isSameMonth(startDate, endDate)) {
    return `${formatDate(startDate, 'd')} - ${formatDate(endDate, 'd MMM')}`;
  }

  return `${formatDate(startDate, 'd MMM')} - ${formatDate(endDate, 'd MMM')}`;
};

export const getWeekRangeByStartDate = (startDate: Date) =>
  getWeekRange(startDate, endOfWeek(startDate, { weekStartsOn: 1 }));

export const browserDateFormat = 'yyyy-MM-dd';
export const toBrowserDateFormat = formatFp(browserDateFormat);
export const fromBrowserDateFormat = parseFp(new Date(), browserDateFormat);

const currentWeekStartDate = toBrowserDateFormat(
  startOfWeek(new Date(), { weekStartsOn: 1 }),
);
const currentWeekEndDate = toBrowserDateFormat(
  addWeeks(new Date(currentWeekStartDate), 1),
);

export const currentWeek = {
  startDate: currentWeekStartDate,
  endDate: currentWeekEndDate,
};

const currentMonthStartDate = toBrowserDateFormat(startOfMonth(new Date()));
const currentMonthEndDate = toBrowserDateFormat(
  addMonths(new Date(currentMonthStartDate), 1),
);

export const currentMonth = {
  startDate: currentMonthStartDate,
  endDate: currentMonthEndDate,
};

export const today = {
  startDate: toBrowserDateFormat(new Date()),
  endDate: toBrowserDateFormat(new Date()),
};

export const prettifyItemDate = (rawDate: string) => {
  const date = utcToZonedTime(new Date(rawDate), userStore.userTimezone);

  if (isToday(date)) {
    return `Today, ${formatDate(date, 'HH:mm')}`;
  }

  if (isYesterday(date)) {
    return `Yesterday, ${formatDate(date, 'HH:mm')}`;
  }

  if (isThisWeek(date)) {
    return formatDate(date, 'iiii HH:mm');
  }

  return formatDate(date, 'd MMM HH:mm');
};

export const formatDateTime = (date?: string) => {
  if (!date) return '';

  return formatDate(parseISO(date), 'dd.MM.yyyy HH:mm');
};
