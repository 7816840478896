import React from 'react';

import dateFns from '@date-io/date-fns';
import {
  DateTimePicker as MuiDateTimePicker,
  KeyboardDatePicker,
  DatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { KeyboardDatePickerProps } from '@material-ui/pickers/DatePicker/DatePicker';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import formatISO from 'date-fns/formatISO';
import locale from 'date-fns/locale/en-GB';
import parseISO from 'date-fns/parseISO';

import { useStyles } from './styles';

export type Props = Omit<KeyboardDatePickerProps, 'value' | 'onChange'> & {
  value?: ParsableDate;
  onChange?: (date: string | null) => void;
  style?: React.CSSProperties;
  disableKeyboard?: boolean;
};

const defaultDateFormat = 'dd.MM.yyyy';
const noop = () => {};

const formatDateOnSubmit = (date: MaterialUiPickersDate) => {
  if (date instanceof Date && !isNaN(date.valueOf())) {
    return formatISO(date);
  }

  return null;
};

const prepareValue = (value?: any) => {
  if (value === '' || value === null) {
    return null;
  }

  return parseISO(value);
};

const DatePicker = ({
  value = null,
  onChange = noop,
  placeholder,
  disableKeyboard,
  format,
  ...rest
}: Props) => {
  const { root } = useStyles();

  const Picker = disableKeyboard ? MuiDatePicker : KeyboardDatePicker;

  return (
    <MuiPickersUtilsProvider utils={dateFns} locale={locale}>
      <Picker
        disableToolbar
        variant="dialog"
        inputVariant="outlined"
        className={root}
        format={format || defaultDateFormat}
        value={prepareValue(value)}
        onChange={value => onChange(formatDateOnSubmit(value))}
        placeholder={placeholder || defaultDateFormat}
        autoOk
        clearable
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;


export const DateTimePicker = ({
  value = null,
  onChange = noop,
  placeholder,
  disableKeyboard,
  format,
  label,
  ...rest
}: Props) => {
  return (
    <MuiPickersUtilsProvider utils={dateFns} locale={locale}>
      <MuiDateTimePicker
        fullWidth
        label={label}
        inputVariant="outlined"
        value={prepareValue(value)}
        onChange={value => onChange(formatDateOnSubmit(value))}
        ampm={false}
        format="dd.MM.yyyy HH:mm"
        {...rest}
      />
      
    </MuiPickersUtilsProvider>
  )
}