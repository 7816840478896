import { LogLevel } from '@azure/msal-common';

export const msalConfig = {
  auth: {
    authority: `${process.env.REACT_APP_CONFIG_Authority}`,
    clientId: `${process.env.REACT_APP_CONFIG_ClientID}`,
    redirectUri: `${process.env.REACT_APP_CONFIG_Redirect_URI}`,
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: `${process.env.REACT_APP_CONFIG_Post_LogoutRedirect_URI}`,
  },
  cache: {
    cacheLocation: process.env.REACT_APP_CONFIG_Cache_Location,
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // eslint-disable-next-line no-console
            console.error(message);
            return;
          case LogLevel.Info:
            // eslint-disable-next-line no-console
            console.info(message);
            return;
          case LogLevel.Verbose:
            // eslint-disable-next-line no-console
            console.debug(message);
            return;
          case LogLevel.Warning:
            // eslint-disable-next-line no-console
            console.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false,
  },
};

export const authenticationParameters = {
  scopes: [`${process.env.REACT_APP_CONFIG_Scopes}`],
};

export const authenticationParametersFile = {
  scopes: [process.env.REACT_APP_CONFIG_FILE_Scopes],
};
