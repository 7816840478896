import { TimePeriod as TimePeriodType } from '../api-types';
import { formatDate, formatMinutesDuration } from 'src/utils/date';
import { routerStore } from 'src/stores/router';
import { JobType } from 'src/api/api-types/lookups';
import { createColorGetter } from 'src/utils/colors';

const getColor = createColorGetter();

export class TimePeriod {
  readonly dateDay: string; // ISO Date
  readonly endTime: string; // ISO Date
  readonly jobId: number;
  readonly startTime: string; // ISO Date
  readonly totalMinutes: number;
  readonly taskId: number;
  readonly jobTypes: JobType[];

  constructor(props: TimePeriodType & { jobTypes: JobType[] }) {
    this.dateDay = props.dateDay;
    this.endTime = props.endTime;
    this.jobId = props.jobId;
    this.startTime = props.startTime;
    this.totalMinutes = props.totalMinutes;
    this.taskId = props.taskId;
    this.jobTypes = props.jobTypes;

  }

  get startTimeFormatted() {
    return formatDate(new Date(this.startTime), 'HH:mm');
  }

  get startDateFormatted() {
    return formatDate(new Date(this.startTime), 'iii d');
  }

  get endTimeFormatted() {
    return formatDate(new Date(this.endTime), 'HH:mm');
  }

  get duration() {
    return formatMinutesDuration(this.totalMinutes);
  }

  get currentJobType() {
    return this.jobTypes.find(x => x.key === this.jobId.toString());
  }

  get color() {
    return this.currentJobType?.color || getColor(this.jobId.toString());
  }

  goToTask = () => {
    routerStore?.goTo({ name: 'task', params: { id: this.taskId } });
  };
}
