import { useContext, useEffect, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { observer } from 'mobx-react-lite';
import DatePicker from 'src/components/DatePicker';
import { formatDate } from 'src/utils/date';

import { agendaStoreCtx } from '../store';

import { useStyles } from './styles';

const DateRange = () => {
  const {
    agendaIntervalType,
    startDate,
    setToDate,
    endDate,
    setToRange,
  } = useContext(agendaStoreCtx);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const classes = useStyles();

  const togglePickerOpen = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  useEffect(() => {
    setIsDatePickerOpen(false);
  }, [agendaIntervalType]);

  if (agendaIntervalType === 'day') {
    return (
      <DatePicker
        InputAdornmentProps={{ position: 'start' }}
        variant="inline"
        format="dd.MM.yyyy"
        className={classes.calendar}
        value={startDate.toISOString()}
        open={isDatePickerOpen}
        onOpen={() => setIsDatePickerOpen(true)}
        onClose={() => setIsDatePickerOpen(false)}
        onChange={value => value && setToDate(value)}
        disableKeyboard
      />
    );
  }

  return (
    <div className={classes.dateRangeContainer}>
      <Typography onClick={togglePickerOpen} className={classes.dateRangeLabel}>
        {formatDate(startDate, 'dd.MM.yyyy')} -{' '}
        {formatDate(endDate, 'dd.MM.yyyy')}
      </Typography>
      <DateRangePicker
        value={[startDate, endDate]}
        className={classes.rangePicker}
        isOpen={isDatePickerOpen}
        onCalendarClose={() => setIsDatePickerOpen(false)}
        onChange={(value: Date[]) => setToRange(value)}
      />
    </div>
  );
};

export default observer(DateRange);
