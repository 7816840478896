import { ApiClient } from './';
import {
  LineItemsResponse,
  PriceCatalogResponse,
  LineItemForSaving,
  LineItemsSaveResponse,
  LineItemType,
} from 'src/api/api-types/line-items';

export class LineItemsApi extends ApiClient {
  getLineItems(key: number | string) {
    return this.requestGet<LineItemsResponse>(`Tasks/${key}/lineItems`);
  }
  getPartnerPriceCatalog(partnerId: number | string) {
    return this.requestGetMeta<PriceCatalogResponse[]>(
      `Prices/partner/${partnerId}/priceList/list?portal=2`,
    );
  }
  getExternalPriceCatalog(partnerId: number | string) {
    return this.requestGetMeta<PriceCatalogResponse[]>(
      `Prices/external/priceList/list/${partnerId}?portal=2`,
    );
  }
  getContractorPriceCatalog(partnerId: number | string) {
    return this.requestGetMeta<PriceCatalogResponse[]>(
      `Prices/contractor/priceList/list/${partnerId}?portal=2`,
    );
  }
  getWriteInPrice(id: string | number) {
    return this.requestGetMeta<PriceCatalogResponse[]>(
      `Prices/writein/priceList/list/${id}?portal=2`,
    );
  }

  saveLineItem(key: number | string, data: LineItemForSaving[]) {
    return this.requestPost<LineItemsSaveResponse, LineItemForSaving[]>(
      `LineItems/task/${key}/bulk`,
      data,
    );
  }

  updateLineItem(data: LineItemType[]) {
    return this.requestPut<LineItemsSaveResponse, LineItemType[]>(
      `LineItems/bulk`,
      data,
    );
  }

  deleteLineItem(data: string[]) {
    return this.requestPost<LineItemsSaveResponse, string[]>(
      `LineItems/delete`,
      data,
    );
  }

  updateComment(id: string, comment: string) {
    return this.requestPut(`LineItems/${id}/comment`, { comment });
  }
}
