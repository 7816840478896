export const styles = {
  root: {
    padding: 30,

    '& > h3': {
      padding: '20px 15px',
    },
  },
  logo: {
    width: 167,
    height: 57,
    padding: '20px 16px 8px 16px',
  },
};
