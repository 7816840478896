import { FileEntityType, UploadFileResponse } from 'src/api/api-types/files';
import { FilesApi } from 'src/api/files';
import { action, observable, computed, makeObservable } from 'mobx';
import last from 'lodash/last';
import { ApiReq, emptyValue } from 'src/api';
import { LookupEntity } from 'src/api/api-types/lookups';
import { prettifyItemDate } from '../../utils/date';

const imageExtensions = [
  'jpg',
  'jpeg',
  'image/jpeg',
  'png',
  'image/png',
  'gif',
];

export class FileBase {
  readonly id: number;
  readonly serviceOrderId: number;
  readonly fileName: string;
  readonly description?: string;
  readonly createdBy?: string;
  readonly category?: LookupEntity;
  readonly fileServiceId: number;
  readonly createdDate: string;
  readonly api = new FilesApi();
  readonly chatMessageId?: number;
  @observable.ref filesReq: ApiReq<Blob> = emptyValue;
  @observable.ref deleteReq: ApiReq<UploadFileResponse> = emptyValue;
  @observable.ref deleteFromServiceReq: ApiReq<UploadFileResponse> = emptyValue;

  constructor(file: FileEntityType) {
    makeObservable(this);

    this.id = file.key || file.id;
    this.serviceOrderId = file.serviceOrderId;
    this.fileName = file.fileName;
    this.description = file.description;
    this.category = file.category;
    this.fileServiceId = file.fileServiceId;
    this.createdDate = file.createdDate;
    this.chatMessageId = file.chatMessageId;

    if (typeof file.createdBy === 'object') {
      this.createdBy = file.createdBy.displayText;
    } else {
      this.createdBy = file.createdBy;
    }
  }

  get extension() {
    return last(this.fileName.split('.')) || '';
  }

  get createdDatePrettified() {
    return prettifyItemDate(this.createdDate) || '---';
  }

  get descriptionPretty() {
    return this.description || '---';
  }

  get createdByPretty() {
    return this.createdBy || '---';
  }

  get isImage() {
    return imageExtensions.includes(this.extension);
  }

  @computed get src() {
    if (this.filesReq.state === 'fulfilled' && !!this.filesReq.value?.data) {
      return window.URL.createObjectURL(
        new Blob([this.filesReq.value.data as Blob]),
      );
    }
    return '';
  }

  @action fetchSrc = async () => {
    if (!!this.src) return;

    this.filesReq = this.api.getFilesUrl(this.fileServiceId);

    await this.filesReq;
  };

  @action delete = () => {
    this.deleteReq = this.api.deleteFile(this.id);
    this.deleteFromServiceReq = this.api.deleteFileFromService(
      this.fileServiceId,
    )
    return Promise.allSettled([this.deleteReq, this.deleteFromServiceReq]);
  };

  @action download = async () => {
    await this.fetchSrc();

    const element = document.createElement('a');
    element.setAttribute('href', this.src);
    element.setAttribute('download', this.fileName);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    element.remove();
  };
}
