

import last from 'lodash/last';
import { FileQuestionnaireEntity } from '../api-types';
import { QuestionnaireFile } from './questionnaire-file';
import { makeObservable } from 'mobx';

export class QuestionnaireGalleryFile extends QuestionnaireFile {

  readonly fileName: string;
  readonly original: string;
  readonly thumbnail: string;
  readonly loadingOriginal: boolean;
  readonly loadingThumbnail: boolean;
  readonly type: string;
  readonly relatedEntity?: string;
  readonly serviceOrderId: number;
  readonly createdBy: string;

  constructor(file: FileQuestionnaireEntity, relatedEntity?: string) {
    super(file);
    makeObservable(this);
    this.fileName = file.name;
    this.original = `${file.id}`;
    this.thumbnail = `${file.id}`;
    this.loadingOriginal = false;
    this.loadingThumbnail = false;
    this.type = this.extension;
    this.relatedEntity = relatedEntity;
    this.serviceOrderId = 0;
    this.createdBy = file.createdByName;
  }

  get extension(): string {
    return last(this.name.split('.')) || '';
  }

  get descriptionPretty() {
    return this.description || '---';
  }

  get createdByPretty() {
    return this.createdBy || '---';
  }

}
