import { IObservableArray, makeObservable, observable } from 'mobx';
import { FileBase } from 'src/models/file';
import { CreatedBy, Message } from '../api-types';
import { prettifyItemDate } from 'src/utils/date';

export class CommentMessage {
  readonly key: string;
  readonly threadKey: string;
  readonly isImportant: boolean;
  readonly message: string;
  readonly createdDate: string;
  readonly createdBy: CreatedBy;
  readonly isShared: boolean;
  readonly sharedChannel: string;
  readonly sharedThread: string;
  readonly isSharedByEmail: boolean;
  readonly isUnread: boolean;
  @observable.ref files: IObservableArray<FileBase> = observable([]);

  constructor(props: Message) {
    makeObservable(this);

    this.key = props.key;
    this.threadKey = props.threadKey;
    this.isImportant = props.isImportant;
    this.message = props.message;
    this.createdDate = props.createdDate;
    this.createdBy = props.createdBy;
    this.isShared = props.isShared;
    this.sharedChannel = props.sharedChannel;
    this.sharedThread = props.sharedThread;
    this.isSharedByEmail = props.isSharedByEmail;
    this.isUnread = props.isUnread;

    this.files.replace(
      props.files.map(file => new FileBase({ ...file, id: file.key })),
    );
  }

  get timestampPretty() {
    return prettifyItemDate(this.createdDate);
  }
}
