import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import {
  getMainColor,
  WithMonteraColors,
} from '../../utils/theme/montera-main';

export default ({
  color = 'primary',
  ...props
}: WithMonteraColors<SvgIconProps>) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect
      x="14.5"
      y="2"
      width="12"
      height="3"
      transform="rotate(90 14.5 2)"
      fill="none"
      stroke={getMainColor(color)}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="9.5"
      y="2"
      width="12"
      height="3"
      transform="rotate(90 9.5 2)"
      fill="none"
      stroke={getMainColor(color)}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="4.5"
      y="2"
      width="12"
      height="3"
      transform="rotate(90 4.5 2)"
      fill="none"
      stroke={getMainColor(color)}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
