import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    bar: {
      minHeight: 48,
      display: 'grid',
      gridTemplateColumns: '36px 1fr 36px',
    },
    popper: {
      position: 'relative',
      top: -36,
    },
    secondaryAction: {
      right: 0,
    },
    radioWrapper: {
      marginRight: 12,
      '& > .MuiRadio-root': {
        padding: 0,
      },
    },
    radio: {
      '&$checked': {
        color: '#2B59C3 !important',
      },
    },
    checked: {},
    calendar: {
      width: 90,
      '& > .MuiInputBase-root': {
        '& > .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
      '& .MuiInputBase-input': {
        padding: 0,
      },
    },
    calendarIcon: {
      margin: '0 12px',
    },
    listItem: {
      paddingLeft: 10,
      paddingRight: 110,
    },
    listItemIcon: {
      minWidth: 30,
    },
    listItemPrimaryText: {
      color: '#2B59C3',
      textTransform: 'uppercase',
    },
    listItemSecondaryText: {
      textTransform: 'uppercase',
    },
    dateRangeContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      top: 1,
    },
    rangePicker: {
      '& > .react-daterange-picker__wrapper': {
        visibility: 'hidden',
        position: 'absolute',
      },
      '& .react-daterange-picker__calendar': {
        left: '-45vw !important',
        top: '20px !important',
      },
    },
    datesNavigation: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    datesNavigationButton: {
      padding: 0,
    },
    dateRangeLabel: {
      fontSize: 14,
    },
  }),
);
