import AgendaNavigationIcon from 'src/assets/icons/NavigationIcons/AgendaNavigationIcon';
import HomeNavigationIcon from 'src/assets/icons/NavigationIcons/HomeNavigationIcon';
import TimesheetNavigationIcon from 'src/assets/icons/NavigationIcons/TimesheetNavigationIcon';
import TodayAgendaNavigationIcon from 'src/assets/icons/NavigationIcons/TodayAgendaNavigationIcon';
import { RouteName } from 'src/routes';
import { currentMonth, today } from 'src/utils/date';

interface MenuItem<T extends object = {}> {
  title: string;
  icon: JSX.Element;
  route: RouteName;
  params?: T;
}

export const menuItems: MenuItem[] = [
  { title: 'Home', icon: <HomeNavigationIcon />, route: 'index' },
  {
    title: `Today's Agenda`,
    icon: <TodayAgendaNavigationIcon />,
    route: 'agenda',
    params: today,
  },
  {
    title: 'Agenda',
    icon: <AgendaNavigationIcon />,
    route: 'agenda',
    params: currentMonth,
  },
  {
    title: 'Timesheet',
    icon: <TimesheetNavigationIcon />,
    route: 'timesheet',
  },
];
