import { lazy } from 'react';

import { MonteraRoute } from 'src/utils/router';

export const LoginRoute: MonteraRoute<'login'> = {
  name: 'login',
  path: '/login',
  comp: () => lazy(() => import('./index')),
};

export const UnauthenticatedHomepage: MonteraRoute<'unauthenticated-root'> = {
  name: 'unauthenticated-root',
  path: '/',
  comp: () => lazy(() => import('./index')),
};
