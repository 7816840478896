import { LineItemsApi } from 'src/api/line-items';
import { action, computed, makeObservable, observable } from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import {
  LineItemsSaveResponse,
  LineItemType,
} from 'src/api/api-types/line-items';
import { LineItemTargetType } from 'src/types';
import { isInt } from 'src/utils/common';
import { isFinalisedSOStatus } from 'src/utils/statuses';
import { Task } from '../../../models/task';

export class LineItem {
  apiLineItems = new LineItemsApi();
  @observable qty: number;
  @observable.ref deleteReq: ApiReq<LineItemsSaveResponse> = emptyValue;
  @observable.ref updateLineItemReq: ApiReq<LineItemsSaveResponse> = emptyValue;
  @observable.ref updateCommentReq: ApiReq<unknown> = emptyValue;
  @observable.ref task: Task;

  readonly id: string;
  readonly lineItemType: LineItemTargetType;
  readonly lineItemName: string;
  readonly unitName: string;
  readonly price: number;
  readonly priceVat: number;
  readonly priceListItemType: number;
  readonly description: string;
  readonly comment?: string;
  readonly sum: number;
  readonly sumVat: number;
  readonly isDecimalQuantity?: boolean;

  constructor({
    id,
    lineItemType,
    lineItemName,
    unitName,
    price,
    priceVat,
    priceListItemType,
    description,
    qty,
    sum,
    sumVat,
    comment,
    isDecimalQuantity,
  }: LineItemType, task: Task) {
    makeObservable(this);

    this.id = id;
    this.lineItemType = lineItemType;
    this.lineItemName = lineItemName;
    this.unitName = unitName;
    this.price = price;
    this.priceVat = priceVat;
    this.priceListItemType = priceListItemType;
    this.description = description;
    this.qty = qty;
    this.sum = sum;
    this.sumVat = sumVat;
    this.comment = comment;
    this.isDecimalQuantity = isDecimalQuantity;
    this.task = task;
  }

  @computed get descriptionPretty() {
    return this.description || '---';
  }

  @computed get commentPretty() {
    return this.comment || '---';
  }

  @computed get serialize() {
    return {
      id: this.id,
      lineItemType: this.lineItemType,
      lineItemName: this.lineItemName,
      unitName: this.unitName,
      priceListItemType: this.priceListItemType,
      price: this.price,
      priceVat: this.priceVat,
      description: this.description,
      qty: this.qty,
      sum: this.sum,
      sumVat: this.sumVat,
    };
  }

  @computed get isValid() {
    if (!this.isDecimalQuantity) {
      return isInt(this.qty)
    }

    return true;
  }

  @computed get isFinalised() {
    return isFinalisedSOStatus(this.task.taskServiceOrderDetails.serviceOrderStatusId)
  }

  @action delete = async () => {
    this.deleteReq = this.apiLineItems.deleteLineItem([this.id]);
    return this.deleteReq;
  };

  @action updateQty = (value: number) => {
    this.qty = value;
  };

  @action save = async () => {
    this.updateLineItemReq = this.apiLineItems.updateLineItem([this.serialize]);

    return this.updateLineItemReq;
  };

  @action updateComment = async (comment: string) => {
    this.updateCommentReq = this.apiLineItems.updateComment(this.id, comment);

    return this.updateCommentReq;
  };
}
