import { CommentsApi } from './api';
import { storeFactory } from 'src/utils/store';
import { LookupsApi } from 'src/api/lookups';
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import { Channel } from 'src/api/api-types/lookups';
import {
  ChatMessageResponse,
  ThreadEntityType,
  ThreadListResponse,
  ThreadResponse,
} from './api-types';
import { taskStore } from '../../store';
import { UploadFile } from 'src/models/file/upload-file';
import { CommentsThread } from './models/thread';
import { routerStore } from 'src/stores/router';

const allowedChannels = ['Worker', 'Public'];
const readOnlyChannels = ['Public'];

class CommentsStore {
  readonly commentsApi = new CommentsApi();
  readonly lookupsApi = new LookupsApi({ prefix: '' });

  @observable.ref channelsReq: ApiReq<Channel[]> = emptyValue;
  @observable.ref
  serviceOrderThreadListReq: ApiReq<ThreadListResponse> = emptyValue;
  @observable.ref serviceThreadListReq: ApiReq<ThreadListResponse> = emptyValue;
  @observable.ref threadReq: ApiReq<ThreadResponse> = emptyValue;
  @observable.ref sendMessageReq: ApiReq<ChatMessageResponse> = emptyValue;

  @observable currentChannel?: string;
  @observable currentThread?: number;
  @observable showRelatedComments = false;

  @observable.ref thread?: CommentsThread;

  constructor() {
    makeObservable(this);

    reaction(
      () => [taskStore.serviceId, taskStore.serviceOrderId],
      () => {
        this.resetCurrentChannel();
      },
    );

    reaction(
      () => [this.currentChannel, this.showRelatedComments],
      () => this.fetchThreadList(),
    );
  }

  @computed get channels() {
    if (this.channelsReq.state !== 'fulfilled') {
      return [];
    }

    return this.channelsReq.value.data?.filter(({ matchingText }) =>
      allowedChannels.includes(matchingText),
    );
  }

  @computed get serviceOrderThreadList() {
    if (this.serviceOrderThreadListReq.state !== 'fulfilled') {
      return [];
    }

    return this.serviceOrderThreadListReq.value.data?.threads || [];
  }

  @computed get serviceThreadList() {
    if (this.serviceThreadListReq.state !== 'fulfilled') {
      return [];
    }

    return this.serviceThreadListReq.value.data?.threads || [];
  }

  @computed get threadList() {
    if (this.showRelatedComments) {
      return this.serviceOrderThreadList;
    }

    return this.serviceThreadList;
  }

  @computed get currentChannelPretty() {
    if (!this.currentChannel) {
      return '';
    }

    return (
      this.channels?.find(({ key }) => key === this.currentChannel)
        ?.displayText || ''
    );
  }

  @computed get sendMessageResponse() {
    if (this.sendMessageReq.state !== 'fulfilled') {
      return null;
    }

    return this.sendMessageReq.value.data;
  }

  @computed get canWriteInChannel() {
    return (
      !!this.currentChannel &&
      !readOnlyChannels.includes(this.currentChannelPretty)
    );
  }

  @computed get hasSelectedChannel() {
    return !!routerStore?.route.params.channel;
  }

  @action fetchChannels = async (currentChannel?: string) => {
    this.channelsReq = this.lookupsApi.getChatChannels();

    await this.channelsReq;

    const channel = this.channels?.find(
      ({ matchingText }) => matchingText === currentChannel,
    );

    runInAction(() => {
      if (channel) this.setChannel(channel.key);
    });
  };

  @action setChannel = (channel: string) => {
    this.currentChannel = channel;
  };

  @action selectChannel = (channelId: string) => {
    const channel =
      this.channels?.find(({ key }) => key === channelId)?.matchingText || '';

    routerStore?.goTo(
      { ...routerStore?.route, params: { channel } },
      { replace: true },
    );
  };

  @action setThread = (thread: number) => {
    this.currentThread = thread;
  };

  @action resetCurrentChannel = () => {
    this.currentChannel = undefined;
    this.currentThread = undefined;
  };

  @action fetchServiceOrderThreads = () => {
    if (!this.currentChannel || !taskStore.serviceOrderId) {
      throw new Error('cannot fetch threads, choose a channel first');
    }

    this.serviceOrderThreadListReq = this.commentsApi.getServiceOrderThreadList(
      taskStore.serviceOrderId,
      this.currentChannel,
    );

    return this.serviceOrderThreadListReq;
  };

  @action fetchServiceThreads = () => {
    if (!this.currentChannel || !taskStore.serviceId) {
      throw new Error('cannot fetch threads, choose a channel first');
    }

    this.serviceThreadListReq = this.commentsApi.getServiceThreadList(
      taskStore.serviceId,
      this.currentChannel,
    );

    return this.serviceThreadListReq;
  };

  @action fetchThread = async () => {
    if (!this.currentThread) {
      throw new Error('cannot fetch thread content, select a thread first');
    }

    this.threadReq = this.commentsApi.getThread(
      this.currentThread,
      this.currentChannel!,
    );

    await this.threadReq;

    runInAction(() => {
      if (
        this.threadReq.state !== 'fulfilled' ||
        !this.threadReq.value.data?.chatThread
      ) {
        return;
      }

      this.thread = new CommentsThread(this.threadReq.value.data.chatThread);
    });
  };

  @action sendMessage = async (messageText: string, files: UploadFile[]) => {
    if (!this.thread) {
      throw Error('Cannot send message, please select thread first');
    }

    if (this.sendMessageReq.state !== 'fulfilled') return;

    const { title, key, parentEntityKey, parentEntityType } = this.thread;

    this.sendMessageReq = this.commentsApi.sendMessage({
      messageText,
      messageThread: {
        threadChannelKey: this.currentChannel,
        threadTitle: title,
        threadKey: key,
        entityKey: parentEntityKey,
        entityType: parentEntityType,
      },
    });

    await this.sendMessageReq;

    await Promise.allSettled(
      files.map(file => file.linkToMessage(this.sendMessageResponse!.key)),
    );

    await this.fetchThread();

    return this.sendMessageReq;
  };

  @action createThread = async (
    message: string,
    title: string,
    entityType: ThreadEntityType,
  ) => {
    if (!taskStore.serviceOrderId) {
      return;
    }

    this.sendMessageReq = this.commentsApi.sendMessage({
      messageText: message,
      messageThread: {
        threadChannelKey: this.currentChannel,
        threadTitle: title,
        threadKey: '0', // magic number for API to understand that thread needs to be created
        entityKey:
          entityType === ThreadEntityType.ServiceOrder
            ? `${taskStore.serviceOrderId}`
            : `${taskStore.serviceId}`,
        entityType,
      },
    });

    await this.sendMessageReq;

    if (entityType === ThreadEntityType.Service) {
      this.fetchServiceThreads();
    }

    if (entityType === ThreadEntityType.ServiceOrder) {
      this.fetchServiceOrderThreads();
    }
  };

  @action toggleShowRelatedComments = () => this.showRelatedComments = !this.showRelatedComments;

  @action fetchThreadList = () => {
    if (this.showRelatedComments) {
      return this.fetchServiceOrderThreads()
    }

    return this.fetchServiceThreads()
  }
}

export const {
  store: commentsStore,
  storeCtx: commentsStoreCtx,
} = storeFactory(CommentsStore, 'comments');
