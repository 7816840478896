import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const TimesheetNavigationIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    {...props}>
    <path d="M8 16A8 8 0 108 0a8 8 0 000 16z" fill="#fff" fillOpacity=".5" />
    <path
      d="M8 3.2V8l2.4 2.4"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default TimesheetNavigationIcon;
