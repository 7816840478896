import { useState, useContext, ChangeEvent } from 'react';

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import RadioGroup from '@material-ui/core/RadioGroup';
import Toolbar from '@material-ui/core/Toolbar';
import { observer } from 'mobx-react-lite';
import FilterIcon from 'src/assets/icons/FilterIcon';
import { AgendaIntervalType } from 'src/types';

import { agendaStoreCtx } from '../store';

import DateRange from './DateRange';
import DatesNavigation from './DatesNavigation';
import IntervalTypeItem from './IntervalTypeItem';
import { iconsMap } from './icons';
import { useStyles } from './styles';

const intervals: AgendaIntervalType[] = ['day', 'week', 'month'];

export default observer(() => {
  const {
    agendaIntervalType,
    setAgendaIntervalType,
    setFilterOpen,
  } = useContext(agendaStoreCtx);
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAgendaIntervalType(event.currentTarget.value as AgendaIntervalType);
    setOpen(false);
  };
  const handleClickIntervalItemClick = (type: AgendaIntervalType) => {
    setAgendaIntervalType(type);
    setOpen(false);
  };

  const { bar, calendarIcon } = useStyles();

  const Icon = iconsMap[agendaIntervalType];

  return (
    <Toolbar className={bar}>
      <IconButton edge="start" color="inherit" onClick={toggleOpen}>
        <Icon color="primary" />
      </IconButton>
      <Drawer anchor="bottom" open={open} onClose={toggleOpen}>
        <RadioGroup value={agendaIntervalType} onChange={handleChange}>
          <List>
            {intervals.map(type => (
              <IntervalTypeItem
                key={type}
                onChange={handleClickIntervalItemClick}
                type={type}
                value={agendaIntervalType}
              />
            ))}
          </List>
        </RadioGroup>
      </Drawer>
      <DatesNavigation>
        <DateRange />
      </DatesNavigation>
      <FilterIcon
        className={calendarIcon}
        onClick={() => setFilterOpen(true)}
      />
    </Toolbar>
  );
});
