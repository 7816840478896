import diff from 'lodash/difference';
import random from 'lodash/random';

const beigeish = ['#E1DABD', '#BDC3A1', '#97AD89', '#6F9776'];
const purpleish = ['#FFE4FA', '#FFD7D4', '#FFD9AD', '#D0CAEB'];
const pinkish = ['#FFC4EB', '#FFC1D9', '#FFC3BB', '#FFE5FF'];
const lightPinkish = ['#F1DEDC', '#D9C0C6', '#BCA4B5', '#978CA7'];
const blueish = ['#CFF6FF', '#A0B3D6', '#A3FFE7', '#A3FFE7', '#AAE8E8'];
const greenish = ['#DFE5CB', '#B1C6B1', '#86A69B', '#90B39A', '#ECF5CE'];
const yellowish = ['#FCFCD4', '#F3F3CC', '#FFEAB3', '#FFDBA2'];

const colors = [
  ...beigeish,
  ...purpleish,
  ...pinkish,
  ...lightPinkish,
  ...blueish,
  ...greenish,
  ...yellowish,
];

export const createColorGetter = () => {
  let usedColorsCache: Record<string, string> = {};

  return (title: string): string => {
    if (usedColorsCache[title]) {
      return usedColorsCache[title];
    }

    const unusedColors = diff(colors, Object.values(usedColorsCache));
    const newColor = unusedColors[random(0, unusedColors.length - 1)];

    usedColorsCache[title] = newColor;

    return newColor;
  };
};
