import { CreateLineItemBase } from './base';
import { LineItemTargetType, PriceListItemType } from 'src/types';
import { Item, LineItemForSaving } from 'src/api/api-types/line-items';
import { computed } from 'mobx';

export class WriteInLineItem extends CreateLineItemBase {
  readonly writeInPrice?: string;
  readonly writeInDesc?: string;
  readonly writeInVat?: string;
  readonly writeInUnitId?: string;

  readonly invoiceTo = LineItemTargetType.WriteIn;
  readonly priceListItemType = PriceListItemType.WriteIn;

  constructor(props: Item & Partial<LineItemForSaving>) {
    super(props);

    this.writeInPrice = props.writeInPrice;
    this.writeInDesc = props.writeInDesc;
    this.writeInVat = props.writeInVat;
    this.writeInUnitId = props.writeInUnitId;
  }

  @computed get serialized() {
    return {
      priceListItemId: this.key,
      lineItemType: this.lineItemType,
      priceListItemType: this.priceListItemType,
      qty: this.qty,
      writeInPrice: this.writeInPrice,
      writeInDesc: this.writeInDesc,
      writeInVat: `${this.vat}`,
      writeInUnitId: this.writeInUnitId,
      lineItemInvoicedId: this.lineItemInvoicedId,
      comment: this.comment,
      sellerId: this.sellerId,
    };
  }
}
