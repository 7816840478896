import { FileSignType } from 'src/api/api-types/files';

export type AgendaIntervalType = 'day' | 'week' | 'month';

export interface Pageable {
  currentPage: number;
  pageCount: number;
  pageSize: number;
  totalCount: number;
  success: boolean;
  errors?: string[];
  totalThreads: number;
}

export type MonteraResponse<T> = T & { success: boolean; errors?: string[] };
export type MonteraPageableResponse<T> = T & Pageable;

export interface SelectOptionItem<T = string | number> {
  name: string;
  value: T;
}

export enum TimesheetRangeType {
  'today',
  'yesterday',
  'this_week',
  'this_month',
  'last_week',
  'last_month',
  'custom',
}

export enum FilesFilterType {
  AllFiles = 'allFiles',
  ServiceFiles = 'serviceFiles',
  SOFiles = 'SOFiles',
}

export enum FileStatus {
  PENDING = 0,
  LOADING = 1,
  DONE = 2,
  FAILED = 3,
}

export interface FileUploadPLane {
  fileUploadPlan?: FileSignType;
}

export interface TimesheetRouteParams {
  startDate?: string;
  endDate?: string;
  pageSize?: string;
}

export enum ViewLineItemType {
  Available = 'available',
  Current = 'current',
  Selected = 'selected',
  Success = 'success',
}

export enum LineItemTargetType {
  Partner = 1,
  Customer = 2,
  WriteIn = 3,
  Contractor = 4,
}

export enum PriceListItemType {
  Partner = 1,
  External = 2,
  WriteIn = 3,
  Contractor = 4,
}

export enum FilesViewTypes {
  List = 'list',
  Carousel = 'carousel',
}