import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import agenda_da from 'src/assets/locales/da/agenda.json';
import common_da from 'src/assets/locales/da/common.json';
import files_da from 'src/assets/locales/da/files.json';
import lineItems_da from 'src/assets/locales/da/lineItems.json';
import questionnaire_da from 'src/assets/locales/da/questionnaire.json';
import task_da from 'src/assets/locales/da/task.json';
import timesheet_da from 'src/assets/locales/da/timesheet.json';
import agenda_en from 'src/assets/locales/en/agenda.json';
import common_en from 'src/assets/locales/en/common.json';
import files_en from 'src/assets/locales/en/files.json';
import lineItems_en from 'src/assets/locales/en/lineItems.json';
import questionnaire_en from 'src/assets/locales/en/questionnaire.json';
import task_en from 'src/assets/locales/en/task.json';
import timesheet_en from 'src/assets/locales/en/timesheet.json';

i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    lng: 'en',
    ns: ['common', 'task', 'lineItems'],
    defaultNS: 'main',
    fallbackLng: 'en',
    whitelist: ['en', 'da', 'fi', 'nb', 'sv'],
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        common: common_en,
        lineItems: lineItems_en,
        task: task_en,
        files: files_en,
        questionnaire: questionnaire_en,
        agenda: agenda_en,
        timesheet: timesheet_en,
      },
      da: {
        common: common_da,
        lineItems: lineItems_da,
        task: task_da,
        files: files_da,
        questionnaire: questionnaire_da,
        agenda: agenda_da,
        timesheet: timesheet_da,
      },
    },
  });

export default i18n;
