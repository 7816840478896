import { Job, TimePeriod as TimePeriodType } from '../api-types';
import { computed, IObservableArray, makeObservable, observable } from 'mobx';
import { TimePeriod } from './time-period';
import isFuture from 'date-fns/isFuture';
import { formatDate, formatMinutesDuration } from 'src/utils/date';
import { createColorGetter } from 'src/utils/colors';
import { JobType } from 'src/api/api-types/lookups';

const getColor = createColorGetter();

interface Props {
  job: Job;
  timePeriods: TimePeriodType[];
  jobTypes: JobType[];
}

export class Timesheet {
  @observable.ref timePeriods: IObservableArray<TimePeriod> = observable([]);
  readonly job: Job;

  constructor(props: Props) {
    makeObservable(this);

    this.job = props.job;
    this.timePeriods.replace(
      props.timePeriods.map(
        tp => new TimePeriod({ ...tp, jobTypes: props.jobTypes }),
      ),
    );
  }

  get dateFormatted() {
    return formatDate(new Date(this.job.dateDay), 'iii d');
  }

  get isInFuture() {
    return isFuture(new Date(this.job.dateDay));
  }

  get jobColor() {
    return getColor(this.job.jobType);
  }

  get totalProposedMinutesFormatted() {
    return formatMinutesDuration(this.job.totalProposedMinutes);
  }

  @computed get totalDurationMinutes() {
    return this.timePeriods.reduce(
      (acc, period) => acc + period.totalMinutes,
      0,
    );
  }

  @computed get totalDurationFormatted() {
    return formatMinutesDuration(this.totalDurationMinutes);
  }

  goToTask = () => {
    this.timePeriods[0]?.goToTask();
  };
}
