import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const TodayAgendaNavigationIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 -2 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect width="16" height="10" rx="1" fill="white" fillOpacity="0.5" />
    <rect y="12" width="16" height="3" rx="1" fill="white" fillOpacity="0.5" />
    <rect x="2" y="2" width="5" height="5" fill="white" />
  </SvgIcon>
);

export default TodayAgendaNavigationIcon;
