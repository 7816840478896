import {
  action,
  autorun,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import { storeFactory } from 'src/utils/store';
import { TaskApi } from './api';
import { ApiReq, emptyValue } from 'src/api';
import {
  TaskResponse,
  TimePeriodResponse,
} from './api-types';
import { Task } from './models/task';

class TaskStore {
  readonly api = new TaskApi();
  timerInterval: any = null;
  autoSyncInterval: any = null;

  @observable.ref taskDetailsReq: ApiReq<TaskResponse> = emptyValue;
  @observable.ref currentTimePeriodReq: ApiReq<TimePeriodResponse> = emptyValue;
  @observable.ref task: Task | null = null;
  @observable timerSeconds: number = 0;
  @observable isTimerTicking: boolean = false;
  @observable shouldAddFile: boolean = false;

  constructor() {
    makeObservable(this);

    autorun(() => {
      if (this.isTimerTicking) {
        this.timerInterval = setInterval(
          () => runInAction(() => (this.timerSeconds += 1)),
          1000,
        );

        this.autoSyncInterval = setInterval(
          () => runInAction(() => this.fetchCurrentTimePeriod()),
          30000,
        );

        return;
      }

      clearInterval(this.timerInterval);
      clearInterval(this.autoSyncInterval);
    });
  }

  @computed get taskDetailsRes() {
    if (this.taskDetailsReq.state !== 'fulfilled') return null;

    return this.taskDetailsReq.value.data;
  }

  @computed get taskServiceDetails() {
    if (this.taskDetailsReq.state !== 'fulfilled' || !this.taskDetailsReq.value)
      return undefined;

    return this.taskDetailsReq?.value?.data?.task?.taskServiceDetails;
  }

  @computed get formattedTimer() {
    return new Date(this.timerSeconds * 1000)
      .toISOString()
      .substr(11, 5)
      .replace(':', 'h:')
      .concat('m');
  }

  @computed get currentTimePeriod() {
    if (
      this.currentTimePeriodReq.state !== 'fulfilled' ||
      !this.currentTimePeriodReq.value
    ) {
      return null;
    }

    return {
      timePeriod: this.currentTimePeriodReq.value.data?.timePeriod || null,
      totalTimeSpentMinutes: this.currentTimePeriodReq.value.data?.totalTimeSpentMinutes
    }
  }

  @computed get serviceId() {
    return this.task?.taskServiceDetails.serviceId;
  }

  @computed get serviceName() {
    return this.task?.taskServiceDetails.serviceName;
  }

  @computed get serviceOrderId() {
    return this.task?.taskServiceOrderDetails.serviceOrderId;
  }

  @computed get serviceOrderName() {
    return this.task?.taskServiceOrderDetails.serviceOrderName;
  }

  @action fetchTaskDetails = async (taskId: string) => {
    this.taskDetailsReq = this.api.getTaskDetails(taskId);
    await this.taskDetailsReq;

    runInAction(() => {
      if (this.taskDetailsRes) {
        this.task = new Task({ task: this.taskDetailsRes.task, id: taskId });
      }
    });

    return this.taskDetailsReq;
  };

  @action ensureTaskDetails = (taskId: string) => {
    if (this.task?.id === taskId) {
      return;
    }

    return this.fetchTaskDetails(taskId);
  };

  @action fetchCurrentTimePeriod = async () => {
    if (!this.task?.id) {
      throw new Error('Cant fetch current time period (unknown task)');
    }

    this.currentTimePeriodReq = this.api.getCurrentTimePeriod(this.task.id);

    const timePeriodRes = await this.currentTimePeriodReq;

    runInAction(() => {
      if (timePeriodRes.data?.totalTimeSpentMinutes) {
        this.timerSeconds = timePeriodRes.data?.totalTimeSpentMinutes*60;
      }
    });

    this.syncTimePeriodWithLocalTimer();
  };

  @action syncTimePeriodWithLocalTimer() {
    if (!this.currentTimePeriod?.timePeriod?.startTime) {
      this.isTimerTicking = false;

      return;
    }

    if (!!this.currentTimePeriod?.totalTimeSpentMinutes && this.currentTimePeriod?.totalTimeSpentMinutes > 0) {
      this.timerSeconds = this.currentTimePeriod?.totalTimeSpentMinutes * 60
    }

    this.isTimerTicking = true;
  }

  @action startTimer = async () => {
    if (!this.task?.id) {
      throw new Error('Cannot start timer (unknown task)');
    }

    this.isTimerTicking = true;
    await this.api.startTimePeriod(this.task.id);
    await this.fetchTaskDetails(this.task.id);
  };

  @action stopTimer = async (reasonId: string, reasonComment: string) => {
    if (!this.task?.id) {
      throw new Error('Cannot stop timer (unknown task)');
    }

    this.isTimerTicking = false;
    await this.api.stopTimePeriod(this.task.id, { reasonComment, reasonId });
    await this.fetchTaskDetails(this.task.id);
  };

  @action toggleTriggerAddingFile = () => this.shouldAddFile = !this.shouldAddFile;
}

export const { store: taskStore, storeCtx: taskStoreCtx } = storeFactory(
  TaskStore,
  'task',
);
