import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    background: theme.palette.primary.light,
    minHeight: '100vh',
    // temp fix until overlapping issue is resolved:
    maxWidth: '100vw',
    overflow: 'hidden',
  },
  colorPrimary: {
    background: '#fff',
    color: theme.palette.functional.main,
  },
  toolbar: {
    display: 'grid',
    gridTemplateColumns: '36px 1fr 30px',
  },
  notification: {
    padding: 0,
  },
  alert: {
    fontSize: 16,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    zIndex: 99999,
  },
}));
