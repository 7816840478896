import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import { storeFactory } from 'src/utils/store';
import { LineItemsApi } from 'src/api/line-items';
import { ApiReq, emptyValue } from 'src/api';
import {
  LineItemsResponse,
  LineItemsType,
} from 'src/api/api-types/line-items';
import { taskStore } from '../../store';
import { InvoiceBase } from './models/invoice';

class LineItemsStore {
  apiLineItems = new LineItemsApi();

  @observable.ref lineItemsReq: ApiReq<LineItemsResponse> = emptyValue;
  @observable.ref customerInvoice?: InvoiceBase;
  @observable.ref partnerInvoice?: InvoiceBase;

  constructor() {
    makeObservable(this);
  }


  @computed get lineItemsRes(): LineItemsType['lineItems'] | null {
    if (this.lineItemsReq.state !== 'fulfilled') {
      return null;
    }

    return this.lineItemsReq.value.data?.lineItems || null;
  }


  @action fetchLineItems = async () => {
    if (!taskStore.task?.id) {
      return null;
    }

    this.lineItemsReq = this.apiLineItems.getLineItems(taskStore.task.id);

    await this.lineItemsReq;

    if (!this.lineItemsRes) {
      // network exception will be shown
      return this.lineItemsReq;
    }

    const { customer, partner } = this.lineItemsRes;

    runInAction(() => {
      this.customerInvoice = new InvoiceBase(customer, taskStore.task!);
      this.partnerInvoice = new InvoiceBase(partner, taskStore.task!);
    });

    return this.lineItemsReq;
  };
}

export const {
  store: lineItemsStore,
  storeCtx: lineItemsStoreCtx,
} = storeFactory(LineItemsStore, 'lineItems');
