import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import {
  getMainColor,
  WithMonteraColors,
} from '../../utils/theme/montera-main';

export default ({
  color = 'primary',
  ...props
}: WithMonteraColors<SvgIconProps>) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect
      x="2"
      y="1"
      width="12"
      height="3"
      stroke={getMainColor(color)}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <rect
      x="2"
      y="6"
      width="12"
      height="3"
      stroke={getMainColor(color)}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <rect
      x="2"
      y="11"
      width="12"
      height="3"
      stroke={getMainColor(color)}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
