import { ApiClient } from './';
import {
  UploadFileResponse,
  LinkFileDto,
  FilesResponse,
  LinkFileToMessagePayload,
  LinkedFileResponse,
  ThreadFilesResponse,
  FileSignType,
  LinkFileToQuestionnairePayload,
  TaskServiceReportLinksResponse,
} from 'src/api/api-types/files';
import { MonteraKeyType } from './api-types';

export class FilesApi extends ApiClient {
  getTaskFiles(taskKey: MonteraKeyType) {
    return this.requestGet<FilesResponse>(`files/task/${taskKey}`);
  }
  getCommentSOThreadFiles(
    serviceOrderId: MonteraKeyType,
    threadId: MonteraKeyType,
  ) {
    return this.requestGet<ThreadFilesResponse>(
      `Chat/serviceOrder/${serviceOrderId}/files?threadKey=${threadId}`,
    );
  }
  getCommentServiceThreadFiles(
    serviceId: MonteraKeyType,
    threadId: MonteraKeyType,
  ) {
    return this.requestGet<ThreadFilesResponse>(
      `Chat/service/${serviceId}/files?threadKey=${threadId}`,
    );
  }

  getFilesUrl(key: number | string) {
    return this.requestFiles<Blob>(`files/${key}`);
  }
  getTaskServiceReportLinks(partnerId: MonteraKeyType, jobId: MonteraKeyType) {
    return this.requestGet<TaskServiceReportLinksResponse>(
      `FileQuestionnaires/serviceReportLinks`,
      {
        partnerId,
        jobId,
      }
    );
  }
  deleteFile(key: number | string) {
    return this.requestDelete<UploadFileResponse>(`files/${key}`);
  }
  deleteFileFromService(key: number | string) {
    return this.requestFilesDelete<UploadFileResponse>(`files/${key}`);
  }
  uploadFile(
    file: File,
    fileSign: FileSignType,
    progressCallback?: (progress: number) => void,
  ) {
    return this.requestUploadFile<UploadFileResponse>(
      file,
      fileSign,
      progressCallback,
    );
  }
  linkFile(dto: LinkFileDto) {
    return this.requestPost<LinkedFileResponse, LinkFileDto>('files', dto, {
      hasResponse: true,
    });
  }
  linkFileToQuestionnaire(payload: LinkFileToQuestionnairePayload) {
    return this.requestPost(`FileQuestionnaires`, payload);
  }
  linkFileToChatMessage(
    key: MonteraKeyType,
    payload: LinkFileToMessagePayload,
  ) {
    return this.requestPut(`Files/${key}`, payload);
  }
}
