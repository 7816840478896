import { ApplicationInsights } from '@microsoft/applicationinsights-web';

let appInsightsInstance: ApplicationInsights | null = null;

export const initAppInsights = () => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  appInsightsInstance = new ApplicationInsights({
    config: {
      connectionString: `InstrumentationKey=${process.env.REACT_APP_APP_INSIGHTS_KEY};IngestionEndpoint=${process.env.REACT_APP_APP_INSIGHTS_ENDPOINT}`,
    },
  });

  appInsightsInstance.loadAppInsights();
  appInsightsInstance.trackPageView();
};
