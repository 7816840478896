import { action, computed, makeObservable, observable } from 'mobx';
import { ApiReq, emptyValue } from '../api';
import {
  CustomerLookups,
  JobType,
  SellersResponse,
  ServiceOrders,
  ServiceOrderStatus,
  TaskStatus,
  TaskTimePeriodReason,
} from '../api/api-types/lookups';
import { SelectOptionItem } from '../types';
import { storeFactory } from '../utils/store';
import { LookupsApi } from '../api/lookups';
import { FileCategoryLookupObject } from '../api/api-types/files';
import { Unit } from '../api/api-types/line-items';
import { userStore } from './user';
import { PermissionsResponse } from 'src/api/api-types/paermissions';

class Lookups {
  api = new LookupsApi();
  @observable.ref customersReq: ApiReq<CustomerLookups> = emptyValue;
  @observable.ref serviceOrdersReq: ApiReq<ServiceOrders> = emptyValue;
  @observable.ref jobTypesReq: ApiReq<JobType[]> = emptyValue;
  @observable.ref taskStatusesReq: ApiReq<TaskStatus[]> = emptyValue;
  @observable.ref userPermissionsReq: ApiReq<PermissionsResponse> = emptyValue;
  @observable.ref fileCategoriesReq: ApiReq<
    FileCategoryLookupObject[]
  > = emptyValue;
  @observable.ref questionnaireCategoriesReq: ApiReq<
    FileCategoryLookupObject[]
  > = emptyValue;
  @observable.ref sellersReq: ApiReq<SellersResponse> = emptyValue;
  @observable.ref unitsReq: ApiReq<Unit[]> = emptyValue;
  @observable.ref serviceOrderStatusesReq: ApiReq<
    ServiceOrderStatus[]
  > = emptyValue;
  @observable.ref taskTimePeriodReasonsReq: ApiReq<
    TaskTimePeriodReason[]
  > = emptyValue;

  constructor() {
    makeObservable(this);
  }

  @computed get jobTypes() {
    if (this.jobTypesReq.state !== 'fulfilled') {
      return [];
    }

    return this.jobTypesReq.value.data || [];
  }

  @computed get customersOptions(): SelectOptionItem[] {
    if (this.customersReq.state !== 'fulfilled') {
      return [];
    }

    return (
      this.customersReq.value.data?.customers || []
    ).map(({ displayText, key }) => ({ name: displayText, value: key }));
  }

  @computed get serviceOrdersOptions(): SelectOptionItem[] {
    if (this.serviceOrdersReq.state !== 'fulfilled') {
      return [];
    }

    return (
      this.serviceOrdersReq.value.data?.serviceOrders || []
    ).map(({ displayText, key }) => ({ name: displayText, value: key }));
  }

  @computed get jobTypesOptions(): SelectOptionItem[] {
    if (this.jobTypesReq.state !== 'fulfilled') {
      return [];
    }

    return this.jobTypes.map(({ displayText, key }) => ({
      name: displayText,
      value: key,
    }));
  }

  @computed get taskStatusOptions(): SelectOptionItem[] {
    if (this.taskStatusesReq.state !== 'fulfilled') {
      return [];
    }

    return (
      this.taskStatusesReq.value.data || []
    ).map(({ displayText, key }) => ({ name: displayText, value: key }));
  }

  @computed get fileCategories() {
    if (this.fileCategoriesReq.state !== 'fulfilled') {
      return [];
    }

    return this.fileCategoriesReq.value?.data || [];
  }

  @computed get userPermissions() {
    if (this.userPermissionsReq.state !== 'fulfilled' || !this.userPermissionsReq.value)
      return null;

    return this.userPermissionsReq.value.data;
  }


  @computed get defaultCategory() {
    let defaultCategoryKey = this.fileCategories.find(
      ({ matchingText }) => matchingText.toUpperCase() === 'PICTURE',
    )?.key;

    if (!defaultCategoryKey) {
      defaultCategoryKey = this.fileCategories[0]?.key;
    }

    if (!defaultCategoryKey) {
      return undefined;
    }

    return +defaultCategoryKey;
  }

  @computed get questionnaireCategories() {
    if (this.questionnaireCategoriesReq.state !== 'fulfilled') {
      return [];
    }

    return this.questionnaireCategoriesReq.value?.data || [];
  }

  @computed get defaultQuestionnaireCategory() {
    let defaultCategoryKey = this.questionnaireCategories.find(
      ({ matchingText }) => matchingText.toUpperCase() === 'PICTURE',
    )?.key;

    if (!defaultCategoryKey) {
      defaultCategoryKey = this.questionnaireCategories[0]?.key;
    }

    if (!defaultCategoryKey) {
      return undefined;
    }

    return +defaultCategoryKey;
  }

  @computed get sellers() {
    if (this.sellersReq.state !== 'fulfilled' || !this.sellersReq.value) {
      return [];
    }

    return this.sellersReq?.value?.data?.sellers || [];
  }

  @computed get units() {
    if (this.unitsReq.state !== 'fulfilled' || !this.unitsReq.value.data) {
      return [];
    }

    return this.unitsReq?.value?.data || [];
  }

  @computed get serviceOrderStatuses() {
    if (
      this.serviceOrderStatusesReq.state !== 'fulfilled' ||
      !this.serviceOrderStatusesReq.value.data
    ) {
      return [];
    }

    return this.serviceOrderStatusesReq.value.data || [];
  }

  @computed get taskTimePeriodReasons() {
    if (
      this.taskTimePeriodReasonsReq.state !== 'fulfilled' ||
      !this.taskTimePeriodReasonsReq.value.data
    ) {
      return [];
    }

    return this.taskTimePeriodReasonsReq?.value.data || [];
  }

  @action ensureCustomers = () => {
    if (this.customersOptions.length !== 0) return;

    this.customersReq = this.api.getCustomers();
  };

  @action ensureServiceOrders = () => {
    if (this.serviceOrdersOptions.length !== 0) return;

    this.serviceOrdersReq = this.api.getServiceOrders();
  };

  @action ensureJobTypes = async () => {
    if (this.jobTypesOptions.length !== 0) return;

    this.jobTypesReq = this.api.getJobTypes();

    return this.jobTypesReq;
  };

  @action ensureTaskStatuses = () => {
    if (this.taskStatusOptions.length !== 0) return;

    this.taskStatusesReq = this.api.getTaskStatuses();
  };

  @action ensureFileCategories = () => {
    if (
      this.fileCategories.length !== 0 ||
      this.questionnaireCategoriesReq.state === 'pending'
    ) {
      return;
    }

    this.fileCategoriesReq = this.api.getCategories();
  };

  @action ensureQuestionnaireCategories = () => {
    if (
      this.questionnaireCategories.length !== 0 ||
      this.questionnaireCategoriesReq.state === 'pending'
    ) {
      return;
    }

    this.questionnaireCategoriesReq = this.api.getQuestionnaireCategories();
  };

  @action ensureSellers = async (taskId: string) => {
    if (this.sellers.length !== 0 || this.sellersReq.state === 'pending') {
      return;
    }

    this.sellersReq = this.api.getSellers(taskId);

    return this.sellersReq;
  };

  @action ensureUnits = (partnerId: number) => {
    if (this.units.length !== 0 || this.unitsReq.state === 'pending') {
      return;
    }

    this.unitsReq = this.api.getUnits(partnerId);

    return this.unitsReq;
  };

  @action ensureTaskTimePeriodReasons = () => {
    if (
      this.taskTimePeriodReasons.length !== 0 ||
      this.taskTimePeriodReasonsReq.state === 'pending'
    ) {
      return;
    }

    this.taskTimePeriodReasonsReq = this.api.getTimeStopReasons();

    return this.taskTimePeriodReasonsReq;
  };

  @action fetchPermissions = async () => {
    this.userPermissionsReq = this.api.getFeatureFlags();

    return this.userPermissionsReq;
  };

  @action ensureServiceOrderStatuses = () => {
    if (!userStore.userData) {
      throw new Error('Service orders are only available for logged-in users');
    }

    if (
      this.serviceOrderStatuses.length !== 0 ||
      this.serviceOrderStatusesReq.state === 'pending'
    ) {
      return;
    }

    this.serviceOrderStatusesReq = this.api.getServiceOrderStatuses(
      userStore.userData.profile.territory.key,
    );

    return this.serviceOrderStatusesReq;
  };
}

export const { store: lookupsStore, storeCtx: lookupsStoreCtx } = storeFactory(
  Lookups,
  'lookups',
);
