import { useContext, useState } from 'react';

import Button from '@material-ui/core/Button';
import MenuUI from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import LocaleIcon from 'src/components/LocaleIcon';
import { authStoreCtx } from 'src/stores/auth';
import { userStoreCtx } from 'src/stores/user';

export const useStyles = makeStyles(() => ({
  logoutAction: {
    display: 'flex',
    alignItems: 'flex-end',
    color: 'white',
    padding: '20px 12px',
    fontSize: 18,
    textTransform: 'capitalize',
  },
}));

export const Actions = () => {
  const { msalInstance } = useContext(authStoreCtx);
  const { locales, userLocale, updateUserLocale } = useContext(userStoreCtx);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLng = async (value: number) => {
    await updateUserLocale({
      locale: {
        key: value,
      },
    });

    handleClose();
    // eslint-disable-next-line no-restricted-globals
    if ('reload' in location) location.reload();
  };

  const { logoutAction } = useStyles();

  const handleLogout = () => {
    msalInstance.logoutRedirect();
  };

  return (
    <div
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
      }}>
      <Button color={'secondary'} onClick={handleClick}>
        <span style={{ padding: '0 8px' }}>
          <LocaleIcon ln={userLocale?.iso2} />
        </span>
        {userLocale?.displayText}
      </Button>
      <MenuUI
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {locales?.map(ent => (
          <MenuItem key={ent.id} onClick={() => handleLng(ent.id)}>
            <span style={{ padding: '0 8px' }}>
              <LocaleIcon ln={ent.iso2} />
            </span>
            {ent.englishName}
          </MenuItem>
        ))}
      </MenuUI>
      <Button className={logoutAction} onClick={handleLogout}>
        Logout
      </Button>
    </div>
  );
};

export default observer(Actions);
