import { storeFactory } from 'src/utils/store';
import { action, IObservableArray, observable, autorun } from 'mobx';
import uniqueId from 'lodash/uniqueId';

interface ErrorProps {
  text: string;
  autoDestruct?: boolean;
  autoDestructSeconds?: number;
  host: IObservableArray<Error>;
}

class Error {
  readonly id: string;
  readonly text: string;
  readonly autoDestruct: boolean;
  readonly autoDestructSeconds: number;
  @observable.ref host: IObservableArray<Error>;

  constructor({
    text,
    host,
    autoDestruct = false,
    autoDestructSeconds = 6,
  }: ErrorProps) {
    this.id = uniqueId();
    this.text = text;
    this.autoDestruct = autoDestruct;
    this.autoDestructSeconds = autoDestructSeconds;
    this.host = host;

    if (autoDestruct) {
      autorun(this.remove, {
        name: `error-${this.id}-autodestruction`,
        delay: this.autoDestructSeconds * 1000,
      });
    }
  }

  @action remove = () => this.host.remove(this);
}

// This store contains global view model attributes
// Keep in mind that this store is being used at API level
// so avoid any circular dependencies while importing store/injecting ctx
class GlobalViewModelStore {
  @observable apiErrors = observable([] as Error[]);

  @action setApiErrors = (errors: string[]) => {
    this.apiErrors.replace(
      errors.map(
        text => new Error({ text, host: this.apiErrors, autoDestruct: true }),
      ),
    );
  };
}

export const {
  store: globalViewModelStore,
  storeCtx: globalViewModelStoreCtx,
} = storeFactory(GlobalViewModelStore, 'globalvm');
