import { ApiClient } from './';
import {
  CustomerLookups,
  JobType,
  ServiceOrders,
  TaskStatus,
  SellersResponse,
  Channel,
  TaskTimePeriodReason,
  ServiceOrderStatus,
} from './api-types/lookups';
import { Unit } from 'src/api/api-types/line-items';
import {
  LocationsResponse,
  FileCategoryLookupObject,
} from 'src/api/api-types/files';
import { PermissionsResponse } from 'src/api/api-types/paermissions';

export class LookupsApi extends ApiClient {
  getCustomers() {
    return this.requestGet<CustomerLookups>('Customers/lookups');
  }

  getServiceOrders() {
    return this.requestGet<ServiceOrders>('ServiceOrders/lookups');
  }

  getFeatureFlags() {
    return this.requestGetMeta<PermissionsResponse>(`Configuration/feature-flags/worker-portal`);
  }

  getCategories(partner?: string) {
    return this.requestGetMeta<FileCategoryLookupObject[]>(
      `lookups/filecategories`,
      {
        partner,
      },
    );
  }

  getQuestionnaireCategories(partner?: string) {
    return this.requestGetMeta<FileCategoryLookupObject[]>(
      `Lookups/fileQuestionnaireCategories`,
      {
        partner,
      },
    );
  }

  getLocations(serviceOrderId: number | string) {
    return this.requestGet<LocationsResponse>(
      `Projects/lookups?serviceOrderId=${serviceOrderId}`,
    );
  }

  getJobTypes() {
    return this.requestGetMeta<JobType[]>('Lookups/jobTypes');
  }

  getTaskStatuses() {
    return this.requestGetMeta<TaskStatus[]>('Lookups/taskStatuses');
  }

  getServiceOrderStatuses(territoryKey: number) {
    return this.requestGetMeta<ServiceOrderStatus[]>(
      `Lookups/serviceOrderStatuses?territoryKey=${territoryKey}`,
    );
  }

  getSellers(taskId: string | number) {
    return this.requestGet<SellersResponse>(`Tasks/${taskId}/sellers/lookups`);
  }

  getChatChannels() {
    return this.requestGetMeta<Channel[]>(`Lookups/chatchannels`);
  }

  getUnits(id: string | number) {
    return this.requestGetMeta<Unit[]>(`Prices/partner/${id}/unit/list`);
  }

  getTimeStopReasons() {
    return this.requestGetMeta<TaskTimePeriodReason[]>(
      `lookups/taskTimePeriodReasons`,
    );
  }
}
