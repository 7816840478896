import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { ChatThread, ThreadEntityType } from '../api-types';
import { FilesApi } from 'src/api/files';
import { ApiReq, emptyValue } from 'src/api';
import { CommentMessage } from './message';
import { ThreadFilesResponse } from 'src/api/api-types/files';
import { FileBase } from 'src/models/file';

export class CommentsThread {
  readonly filesApi = new FilesApi();

  readonly key: string;
  readonly title: string;
  readonly isDefault: boolean;
  readonly channelKey: string[];
  readonly parentEntityKey: string;
  readonly parentEntityType: ThreadEntityType;
  readonly headKey: string;

  @observable.ref messages: IObservableArray<CommentMessage> = observable([]);
  @observable.ref filesReq: ApiReq<ThreadFilesResponse> = emptyValue;
  @observable files: IObservableArray<FileBase> = observable([]);

  constructor(props: ChatThread) {
    makeObservable(this);

    this.key = props.key;
    this.title = props.title;
    this.isDefault = props.isDefault;
    this.channelKey = props.channelKey;
    this.parentEntityKey = props.parentEntityKey;
    this.parentEntityType = props.parentEntityType;
    this.headKey = props.headKey;
    this.messages.replace(props.messages.map(msg => new CommentMessage(msg)));
  }

  @computed get filesRes() {
    if (this.filesReq.state !== 'fulfilled') {
      return [];
    }

    return this.filesReq.value.data?.files || [];
  }

  @action fetchFiles = async () => {
    if (this.parentEntityType === 'Service') {
      this.filesReq = this.filesApi.getCommentServiceThreadFiles(
        this.parentEntityKey,
        this.key,
      );
    }

    if (this.parentEntityType === 'ServiceOrder') {
      this.filesReq = this.filesApi.getCommentSOThreadFiles(
        this.parentEntityKey,
        this.key,
      );
    }

    await this.filesReq;

    runInAction(() => {
      this.files.replace(this.filesRes.map(file => new FileBase(file)));
    });
  };
}
