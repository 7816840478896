import { AccountInfo } from '@azure/msal-browser';
import Drawer from '@material-ui/core/Drawer';

import { Menu } from './Menu';
import { useStyles } from './styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  currentAccount: AccountInfo | null;
}

export const LeftMenuMobile = ({
  isOpen,
  handleClose,
  currentAccount,
}: Props) => {
  const { paper } = useStyles();

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={handleClose}
      classes={{ paper }}>
      <Menu currentAccount={currentAccount} onClose={handleClose} />
    </Drawer>
  );
};
