import { PropsWithChildren, useContext } from 'react';

import IconButton from '@material-ui/core/IconButton';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { observer } from 'mobx-react-lite';

import { agendaStoreCtx } from '../store';

import { useStyles } from './styles';

const DatesNavigation = ({ children }: PropsWithChildren<{}>) => {
  const { goPreviousRange, goNextRange } = useContext(agendaStoreCtx);
  const classes = useStyles();

  return (
    <div className={classes.datesNavigation}>
      <IconButton
        onClick={goPreviousRange}
        className={classes.datesNavigationButton}>
        <ChevronLeft />
      </IconButton>
      {children}
      <IconButton
        onClick={goNextRange}
        className={classes.datesNavigationButton}>
        <ChevronRight />
      </IconButton>
    </div>
  );
};

export default observer(DatesNavigation);
