import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import { useTranslation } from 'react-i18next';
import { AgendaIntervalType } from 'src/types';

import { iconsMap } from './icons';
import { useStyles } from './styles';

interface Props {
  type: AgendaIntervalType;
  value: AgendaIntervalType;
  onChange: (type: AgendaIntervalType) => void;
}

export default ({ type, onChange, value }: Props) => {
  const {
    radio,
    checked,
    listItem,
    listItemIcon,
    listItemPrimaryText,
    listItemSecondaryText,
    radioWrapper,
    secondaryAction,
  } = useStyles();
  const { t } = useTranslation('agenda');

  const Icon = iconsMap[type];

  return (
    <ListItem button className={listItem} onClick={() => onChange(type)}>
      <ListItemIcon className={listItemIcon}>
        <Icon color={value === type ? 'primary' : 'functional'} />
      </ListItemIcon>
      <ListItemText
        primary={t(`intervals.${type}`)}
        className={value === type ? listItemPrimaryText : listItemSecondaryText}
      />
      <ListItemSecondaryAction className={secondaryAction}>
        <FormControlLabel
          value={type}
          label=""
          className={radioWrapper}
          control={
            <Radio name="radio-interval" classes={{ root: radio, checked }} />
          }
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};
