import { PropsWithChildren, useContext, useState } from 'react';

import { AccountInfo } from '@azure/msal-browser';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'src/components/Layout/mobile/styles';
import { LeftMenuMobile } from 'src/components/LeftMenu/mobile';

import LayoutInnerBar from './LayoutInnerBar';
import { agendaStoreCtx } from './store';

interface Props {
  currentAccount: AccountInfo | null;
}

const AgendaLayout = ({
  children,
  currentAccount,
}: PropsWithChildren<Props>) => {
  const { wrapper, colorPrimary, toolbar, notification } = useStyles();
  const { isToday } = useContext(agendaStoreCtx);
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation('agenda');
  const close = () => setOpen(false);
  const open = () => setOpen(true);

  return (
    <div className={wrapper}>
      <AppBar position="static" classes={{ colorPrimary }}>
        <Toolbar className={toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={open}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">
            {isToday ? t('today_agenda') : t('agenda')}
          </Typography>
          <IconButton color="inherit" className={notification}>
            <Badge color="error">
              <NotificationsNoneIcon />
            </Badge>
          </IconButton>
        </Toolbar>
        <LayoutInnerBar />
      </AppBar>
      <LeftMenuMobile
        isOpen={isOpen}
        handleClose={close}
        currentAccount={currentAccount}
      />
      {children}
    </div>
  );
};

export default observer(AgendaLayout);
