import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const HomeNavigationIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 5.6L8.2 0l7.2 5.6v8.8c0 .9-.7 1.6-1.6 1.6H2.6c-.9 0-1.6-.7-1.6-1.6V5.6z"
      fill="#fff"
      fillOpacity=".5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.8 16V8h4.8v8"
      fill="#fff"
    />
  </SvgIcon>
);

export default HomeNavigationIcon;
