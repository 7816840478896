
import { FileBase } from './base';
import { FileEntityType } from 'src/api/api-types/files';

export class GalleryFile extends FileBase {
  readonly original: string;
  readonly thumbnail: string;
  readonly loadingOriginal: boolean;
  readonly loadingThumbnail: boolean;
  readonly type: string;
  readonly relatedEntity?: string;


  constructor(file: FileEntityType, serviceName?: string) {
    super(file)
    this.original = `${file.id}`;
    this.thumbnail = `${file.id}`;
    this.loadingOriginal = false;
    this.loadingThumbnail = false;
    this.type = this.extension;
    this.relatedEntity = serviceName;
  }

}
