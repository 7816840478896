import { ApiClient } from 'src/api';
import { MonteraKeyType } from 'src/api/api-types';

import {
  ChatMessagePayload,
  ChatMessageResponse,
  ThreadListResponse,
  ThreadResponse,
} from './api-types';

export class CommentsApi extends ApiClient {
  getCommentTitles() {
    return this.requestGet(`Chat/commentTitles`);
  }

  getWorkOrderThreadList(id: MonteraKeyType) {
    return this.requestGet<ThreadListResponse>(
      `Chat/workOrder/${id}/thread/list`,
    );
  }

  getServiceOrderThreadList(id: MonteraKeyType, channel: string) {
    return this.requestGet<ThreadListResponse>(
      `Chat/serviceOrder/${id}/thread/list`,
      {
        pageSize: 1000,
        currentChannelKey: channel,
      },
    );
  }

  getServiceThreadList(id: MonteraKeyType, channel: string) {
    return this.requestGet<ThreadListResponse>(
      `Chat/service/${id}/thread/list`,
      {
        pageSize: 1000,
        currentChannelKey: channel,
      },
    );
  }

  getThread(id: MonteraKeyType, channel: string) {
    return this.requestGet<ThreadResponse>(`Chat/thread/${id}`, {
      pageSize: 1000,
      currentChannelKey: channel,
    });
  }

  sendMessage(message: ChatMessagePayload) {
    return this.requestPost<ChatMessageResponse, ChatMessagePayload>(
      'Chat/message',
      message,
    );
  }
}
