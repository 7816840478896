import {
  CustomerInfo,
  InvoiceDeliveryInfo,
  PartnerInfo,
  Task as TaskType,
  TaskDetails,
  TaskServiceDetails,
  TaskServiceOrderDetails,
} from '../api-types';

interface Props {
  task: TaskType;
  id: string;
}

export class Task {
  readonly id: string;
  readonly taskDetails: TaskDetails;
  readonly taskServiceOrderDetails: TaskServiceOrderDetails;
  readonly taskServiceDetails: TaskServiceDetails;
  readonly customerInfo: CustomerInfo;
  readonly partnerInfo: PartnerInfo;
  readonly invoiceDeliveryInfo: InvoiceDeliveryInfo;
  readonly specialInstructions: any[];

  constructor({ task, id }: Props) {
    this.id = id;
    this.taskDetails = task.taskDetails;
    this.taskServiceOrderDetails = task.taskServiceOrderDetails;
    this.taskServiceDetails = task.taskServiceDetails;
    this.customerInfo = task.customerInfo;
    this.partnerInfo = task.partnerInfo;
    this.invoiceDeliveryInfo = task.invoiceDeliveryInfo;
    this.specialInstructions = task.specialInstructions;
  }

  get isStatusFinal() {
    return ['Canceled', 'Closed'].includes(this.taskDetails.taskStatusName);
  }
}
