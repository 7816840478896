import { PropsWithChildren } from 'react';

import { useLinkToRoute } from 'mobx-router6';
import { RouteName, routes } from 'src/routes';

interface Props {
  to: RouteName;
  params?: typeof routes[number]['params'];
  onClick?: (e?: React.MouseEvent<any>) => void;
  className?: string;
}

export function Link({
  to,
  params = {},
  children,
  onClick,
  ...rest
}: PropsWithChildren<Props>) {
  const props = useLinkToRoute({ name: to, params, onClick });
  if (!to) {
    // todo [AZ] send error to logger
    return null;
  }

  return (
    <a {...props} {...rest}>
      {children}
    </a>
  );
}
