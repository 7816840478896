import EnFlagIcon from 'src/assets/icons/LocaleIcons/en';
import DaFlagIcon from 'src/assets/icons/LocaleIcons/da';
import FiFlagIcon from 'src/assets/icons/LocaleIcons/fi';
import NbFlagIcon from 'src/assets/icons/LocaleIcons/nb';
import SvFlagIcon from 'src/assets/icons/LocaleIcons/sv';

const LocaleIcon = ({ ln }: { ln: string | undefined }): JSX.Element | null => {
  switch (ln) {
    case "en":
      return <EnFlagIcon />;
    case "da":
      return <DaFlagIcon />;
    case "fi":
      return <FiFlagIcon />;
    case "nb":
      return <NbFlagIcon />;
    case "sv":
      return <SvFlagIcon />;
    default:
      return null;
  }
};

export default LocaleIcon;
