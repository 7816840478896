import { PropsWithChildren, useContext } from 'react';

import { AccountInfo } from '@azure/msal-browser';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { routerStore, RouterStoreCtx } from 'mobx-router6';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'src/components/Layout/mobile/styles';
import { RouteName } from 'src/routes';
import { ViewLineItemType } from 'src/types';

import { TaskDetailsTabs } from '../../types';

interface Props {
  currentAccount: AccountInfo | null;
  currentRoute: RouteName;
}

export default ({ children }: PropsWithChildren<Props>) => {
  const { wrapper, colorPrimary } = useStyles();
  const { t } = useTranslation('lineItems');
  const { route } = useContext(RouterStoreCtx);
  const title =
    route.params.view === ViewLineItemType.Current
      ? t('labels.current_inventory')
      : t('selected_items');

  const goBack = () =>
    routerStore?.goTo({
      name: 'add-line-items',
      params: {
        id: route.params?.id,
        tab: TaskDetailsTabs.LineItems,
        view: ViewLineItemType.Available,
      },
    });

  return (
    <div className={wrapper}>
      <AppBar position="static" classes={{ colorPrimary }}>
        <Toolbar
          style={{ display: 'grid', gridTemplateColumns: '36px 0.9fr 30px' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={goBack}>
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography variant="h6">{title}</Typography>
        </Toolbar>
      </AppBar>
      {children}
    </div>
  );
};
