import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import {
  getMainColor,
  WithMonteraColors,
} from '../../utils/theme/montera-main';

export default ({
  color = 'functional',
  ...props
}: WithMonteraColors<SvgIconProps>) => (
  <SvgIcon
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M13 2.5H2.5C1.67157 2.5 1 3.17157 1 4V14.5C1 15.3284 1.67157 16 2.5 16H13C13.8284 16 14.5 15.3284 14.5 14.5V4C14.5 3.17157 13.8284 2.5 13 2.5Z"
      stroke={getMainColor(color)}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10.75 1V4"
      stroke={getMainColor(color)}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M4.75 1V4"
      stroke={getMainColor(color)}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M1 7H14.5"
      stroke={getMainColor(color)}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <circle cx="4.5" cy="10.5" r="1.5" fill="#2B59C3" />
  </SvgIcon>
);
