import { CreateLineItemBase } from './base';
import { LineItemTargetType, PriceListItemType } from 'src/types';
import { computed } from 'mobx';

export class ExternalLineItem extends CreateLineItemBase {
  readonly invoiceTo = LineItemTargetType.Partner;
  readonly priceListItemType = PriceListItemType.External;

  @computed get serialized() {
    return {
      priceListItemId: this.key,
      lineItemType: this.lineItemType,
      priceListItemType: this.priceListItemType,
      qty: this.qty,
      lineItemInvoicedId: this.lineItemInvoicedId,
      comment: this.comment,
      sellerId: this.sellerId,
    };
  }
}
