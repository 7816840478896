import { ApiClient } from 'src/api';
import { MonteraKeyType } from 'src/api/api-types';

import {
  ServiceType,
  QuestionnairesFileResponse,
  UpdateQuestionnairePayload,
  UpdateQuestionnaireResponse,
} from './api-types';

export class QuestionnairesApi extends ApiClient {
  getQuestionnairesFiles(
    serviceId: MonteraKeyType,
    type: ServiceType = 'service',
  ) {
    return this.requestGet<QuestionnairesFileResponse>(
      `FileQuestionnaires/${type}/${serviceId}`,
    );
  }

  getTaskQuestionnaireFiles(taskId: string) {
    return this.requestGet<QuestionnairesFileResponse>(
      `FileQuestionnaires/task/${taskId}`,
    );
  }

  update(key: MonteraKeyType, body: UpdateQuestionnairePayload) {
    return this.requestPut<
      UpdateQuestionnaireResponse,
      UpdateQuestionnairePayload
    >(`FileQuestionnaires/${key}`, body);
  }
  
  delete(key: MonteraKeyType) {
    return this.requestDelete(`FileQuestionnaires/${key}`);
  }
}
