import { lazy } from 'react';

import { MonteraRoute } from 'src/utils/router';

import { lookupsStore } from '../../stores/lookups';
import { TimesheetRangeType } from '../../types';
import { TaskDetailsTabs } from '../AddLineItems/types';

import { commentsStore } from './Tabs/Comments/store';
import { filesStore } from './Tabs/Files/store';
import { lineItemsStore } from './Tabs/LineItems/store';
import { questionnairesStore } from './Tabs/Questionnaire/store';
import { taskTimePeriodsStore } from './Tabs/Timereport/store';
import { taskStore } from './store';
import { TaskParams } from './types';
import { FilesViewTypes } from 'src/types/index';

export const TaskDetailsRoute: MonteraRoute<'task', Partial<TaskParams>> = {
  name: 'task',
  path: '/task/:id?tab&channel&view',
  comp: () => lazy(() => import('./index')),
  activate: async (routerStore, params) => {
    if (!params?.id) return;

    if (!params.tab) {
      routerStore?.goTo(
        {
          name: 'task',
          params: { id: params.id, tab: TaskDetailsTabs.LineItems },
        },
        { replace: true },
      );
    }
    await taskStore.ensureTaskDetails(params.id);
    await taskStore.fetchCurrentTimePeriod();
    if (params?.tab === TaskDetailsTabs.LineItems) {
      await lineItemsStore.fetchLineItems();
      await lookupsStore.ensureServiceOrderStatuses();
    }

    if (params?.tab === TaskDetailsTabs.Files) {
      await filesStore.fetchTaskFiles({ key: params.id });
      if (params.view === FilesViewTypes.Carousel) {
        await filesStore.loadCarouselFirstImage();
      }
    }

    if (params?.tab === TaskDetailsTabs.Comments) {
      await commentsStore.fetchChannels(params.channel);
    }

    if (params?.tab === TaskDetailsTabs.Questionnaire) {
      await questionnairesStore.fetchQuestionnairesSOFile();
      if (params.view === FilesViewTypes.Carousel) {
        await questionnairesStore.loadCarouselFirstImage();
      }
    }

    if (params?.tab === TaskDetailsTabs.Timereport) {
      taskTimePeriodsStore.setTimesheetRange(TimesheetRangeType.this_month);
      await taskTimePeriodsStore.fetchPeriods();
    }
  },
};
