import Typography from '@material-ui/core/Typography';

import { MonteraLogo } from '../../MonteraLogo';

import { useStyles } from './styles';

const SystemError = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MonteraLogo className={classes.logo} fill="#2B59C3" />
      <Typography variant="h3" component="h3">
        Ooops, Something went wrong.
      </Typography>
    </div>
  );
};

export default SystemError;
