import {
  action,
  makeObservable,
  observable,
  computed,
  IObservableArray,
  runInAction,
} from 'mobx';
import { storeFactory } from 'src/utils/store';
import { ApiReq, emptyValue } from 'src/api';
import {
  QuestionnairesFileResponse,
  ServiceFileQuestionnairesType,
} from './api-types';
import { QuestionnairesApi } from './api';
import { taskStore } from '../../store';
import { QuestionnaireFile } from './models/questionnaire-file';
import { QuestionnaireGalleryFile } from './models/questionnaire-gallery-file';
import { flatMap } from 'lodash';
import { routerStore } from 'src/stores/router';
import { FilesViewTypes } from 'src/types/index';
import { LookupsApi } from 'src/api/lookups';

interface ServiceFileStructTemp extends ServiceFileQuestionnairesType {
  serviceFileQuestionnaires: QuestionnaireFile[];
}

class QuestionnairesStore {
  readonly lookupsApi = new LookupsApi();
  readonly api = new QuestionnairesApi();
  @observable.ref
  questionnairesSReq: ApiReq<QuestionnairesFileResponse> = emptyValue;
  @observable.ref
  questionnairesSOReq: ApiReq<QuestionnairesFileResponse> = emptyValue;
  @observable
  serviceFiles: IObservableArray<ServiceFileStructTemp> = observable([]);
  @observable
  serviceOrdersFiles: IObservableArray<QuestionnaireFile> = observable([]);
  @observable
  carouselFiles: QuestionnaireGalleryFile[] = observable([]);


  constructor() {
    makeObservable(this);
  }

  @computed get serviceOrderDataRes() {
    if (
      this.questionnairesSOReq.state !== 'fulfilled' ||
      !this.questionnairesSOReq.value
    ) {
      return null;
    }
    return this.questionnairesSOReq.value.data?.fileQuestionnaires;
  }


  @computed get serviceQuestionnairesCount() {
    return this.serviceFiles.reduce((acc, cur) => {
      return cur.serviceFileQuestionnaires.length > 0 ? acc + cur.serviceFileQuestionnaires.length : 0;
    }, 0);
  }

  @action fetchQuestionnairesSOFile = async () => {
    if (!taskStore.serviceOrderId) {
      throw new Error('cannot fetch Questionnaires Files, invalid serviceId ');
    }

    this.questionnairesSOReq = this.api.getQuestionnairesFiles(
      taskStore.serviceOrderId,
      'serviceOrder',
    );
    await this.questionnairesSOReq;
    runInAction(() => {
      if (!this.serviceOrderDataRes) return;
      const {
        serviceFileQuestionnaires,
        serviceOrderFileQuestionnaires,
      } = this.serviceOrderDataRes;
      this.serviceOrdersFiles.replace(
        serviceOrderFileQuestionnaires.map(item => new QuestionnaireFile(item)),
      );
      this.serviceFiles.replace(
        serviceFileQuestionnaires.map(entries => ({
          ...entries,
          serviceFileQuestionnaires: entries.serviceFileQuestionnaires.map(
            ent => new QuestionnaireFile(ent),
          ),
        })),
      );
      this.lookupsApi.getFeatureFlags().then(({data}) => {
        if(data?.carouselServiceReports) {
          const SFIles = flatMap(serviceFileQuestionnaires.map((service) => service.serviceFileQuestionnaires.map(file => new QuestionnaireGalleryFile(file, service.serviceName))));
          const SOFIles = serviceOrderFileQuestionnaires.map(file => new QuestionnaireGalleryFile(file));
          this.carouselFiles = (SOFIles.concat(...SFIles));
          this.loadCarouselFirstImage();
        }
      });
    });
  };

  @action loadCarouselFirstImage = () => {
    const firstFile = this.carouselFiles[0];
    if (!!firstFile && routerStore?.route.params?.view === FilesViewTypes.Carousel && firstFile.filesReq.state !== "pending") {
      firstFile.fetchSrc()
    }
  };

  
}

export const {
  store: questionnairesStore,
  storeCtx: questionnairesStoreCtx,
} = storeFactory(QuestionnairesStore, 'questionnaires');