import { lazy } from 'react';

import { MonteraRoute } from 'src/utils/router';

export const NotFoundRoute: MonteraRoute<'404'> = {
  name: '404',
  path: '/404',
  comp: () => lazy(() => import('./index')),

  activate: () => {
    // eslint-disable-next-line no-console
    console.log('activated 404 page');
  },
};
