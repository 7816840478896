import { LinkedFileResponse } from 'src/api/api-types/files';
import { MonteraPageableResponse, MonteraResponse } from 'src/types';

export enum ThreadEntityType {
  ServiceOrder = 'ServiceOrder',
  Service = 'Service',
}

export interface Thread {
  key: number;
  title: string;
  channelKey: string[];
  visibleMessageAuthor: string;
  visibleMessageText: string;
  visibleMessageCreatedDate: Date;
  createdDate: Date;
  order: number;
  parentEntityId: number;
  parentEntityName: string;
  parentEntityType: ThreadEntityType;
  isDefault: boolean;
  countUnreadComments: number;
}

export type ThreadListResponse = MonteraPageableResponse<{ threads: Thread[] }>;

export interface CreatedBy {
  key: string;
  firstName: string;
  lastName: string;
}

export interface CommentFile {
  key: number;
  serviceOrderId: number;
  fileName: string;
  categoryId: number;
  fileServiceId: number;
  description: string;
  createdDate: string;
  sendToIntegration: boolean;
}

export interface Message {
  key: string;
  threadKey: string;
  isImportant: boolean;
  message: string;
  createdDate: string;
  createdBy: CreatedBy;
  isShared: boolean;
  sharedChannel: string;
  sharedThread: string;
  isSharedByEmail: boolean;
  isUnread: boolean;
  childMessages: any[];
  files: CommentFile[];
}

export interface ChatThread {
  key: string;
  title: string;
  isDefault: boolean;
  channelKey: string[];
  parentEntityKey: string;
  parentEntityType: ThreadEntityType;
  headKey: string;
  messages: Message[];
}

export type ThreadResponse = MonteraResponse<{ chatThread: ChatThread }>;

export interface ChatMessagePayload {
  messageText: string;
  messageThread: Partial<{
    threadKey: string;
    threadChannelKey: string;
    entityKey: string;
    entityType: ThreadEntityType;
    threadTitle: string;
  }>;
}

export interface ChatMessageResponse {
  key: string;
  threadKey: string;
  isImportant: boolean;
  message: string;
  createdDate: Date;
  createdBy: CreatedBy;
  isShared: boolean;
  isSharedByEmail: boolean;
  isUnread: boolean;
  childMessages: any[]; // tbd
  files: LinkedFileResponse[];
}
